/* #taketour .tour-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

#taketour .tour-header .tour-header-outer {
  display: flex;
}

#taketour .tour-header .tour-header-col1,
#taketour .tour-header .tour-header-col3 {
  width:20%;
  height: 60px;
}

#taketour .tour-header .tour-header-col2 {
  width: 60%;
  height: 60px;
  display: flex;
  justify-content: center;
}

#taketour .tour-header .tour-header-col2 .tour-tool {
  width: 227px;
  background: blue;
}

#taketour .tour-header .tour-header-col2 .tour-undo {
  width: 50px;
  background: blueviolet;
}

#taketour .tour-header .tour-header-col2 .tour-brush {
  width: 128px;
  background: gold;
}

#taketour .tour-header .tour-header-col2 .tour-color {
  width: 57px;
  background: firebrick;
}

#taketour .tour-header .tour-header-col2 .tour-text {
  width: 138px;
  background: forestgreen;
} */

#taketour .taketour-outer {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  display: flex;
}

#taketour .taketour-outer .tour-main-content {
  background-color: rgba(0,0,0,0.7);
  height: 100vh;
  width: 100%;
} 

#taketour .taketour-outer .sidebar-outer {
  background: #FFF;
  height: 100vh;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#taketour .taketour-outer .sidebar-outer .sidebar-title-blk {
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

#taketour .taketour-outer .sidebar-outer .sidebar-title-blk .title {
  font-family: ProximaNova-SemiBold;
  font-size: 28px;
  color: #000;
}

#taketour .taketour-outer .sidebar-outer .steps-outer {
  background: #FFF;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#taketour .taketour-outer .sidebar-outer .step-blk {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

#taketour .taketour-outer .sidebar-outer .step-blk .step-number {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

#taketour .taketour-outer .sidebar-outer .step-blk .step-number .span {
  font-family: ProximaNova-Regular;
  font-size: 20px;
  color: #000;
}

#taketour .taketour-outer .sidebar-outer .step-blk .step-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

#taketour .taketour-outer .sidebar-outer .step-blk .hr-line {
  width: 1px;
  height: 30px;
  background: #979797;
  margin: 5px 0;
}

#taketour .taketour-outer .sidebar-outer .step-desc p {
  font-family: ProximaNova-Regular;
  font-size: 12px;
  color: #000;
  text-align: center;
}

#taketour .taketour-outer .note-blk { 
  z-index: 3;
  padding: 10px 15px;
  position: absolute;
  border-radius: 10px;
}

#taketour .taketour-outer .note-blk .arrow-blk {
  position: absolute;
  left: 46%;
  width: 8px;
  height: 8px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}

#taketour .taketour-outer .note-blk p {
  font-family: ProximaNova-SemiBold;
  font-size: 13px;
  text-align: center;
  line-height: 1.5;
  color: #242424;
}

#taketour .taketour-outer .note-blk .sidebyside {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#taketour .taketour-outer .note-blk .sidebyside img {
  width: 20%;
}

#taketour .taketour-outer .skip-outer {
  position: absolute;
  bottom: 0;
  background: #64A543;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#taketour .taketour-outer .skip-outer .skip-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#taketour .taketour-outer .skip-outer .skip-blk {
  display: flex;
  align-items: center;
}

#taketour .taketour-outer .skip-outer .skip-blk p {
  color: #FFF;
  font-size: 14px;
  font-family: ProximaNova-Regular;
}

#taketour .taketour-outer .skip-outer .skip-blk .skip-btn {
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
  border-radius: 7px;
  background-color: #FFFFFF;
  color: #BF4C4C;
  font-weight: bold;
  text-align: center;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  margin-left: 10px;
}


/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 200px) and (max-width: 480px) {

  #taketour .taketour-outer .sidebar-outer .sidebar-title-blk {
    margin-bottom: 5px;
  }

  #taketour .taketour-outer .sidebar-outer {
    width: 30%;
  }
  
  #taketour .taketour-outer .sidebar-outer .sidebar-title-blk .title {
    font-size: 16px;
    padding: 0 10px;
  }

  #taketour .taketour-outer .skip-outer .skip-blk {
    width: 80%;
    margin-bottom: 0;
  }

  #taketour .taketour-outer .sidebar-outer .step-blk .step-number {
    width: 20px;
    height: 20px;
  }

  #taketour .taketour-outer .sidebar-outer .step-blk .hr-line {
    height: 15px;
  }

  #taketour .taketour-outer .note-blk p,
  #taketour .taketour-outer .sidebar-outer .step-desc p {
    font-size: 10px;
  }

  #taketour .taketour-outer .note-blk .sidebyside img {
    width: 14%;
  }
}