#drawboard .sidebar {
  width: 23%;
  position: fixed;
  height: 100vh;
  z-index: 2;
  top: 0;
  left: -21%;
  overflow-x: hidden;
  transition: 0.5s;
}

#drawboard .sidebar .sidebar-outer {
  background: #FFF;
  border-right: 1px solid #ddd;
  height: 100vh;
  width: 90%;
  display: flex;
  align-items: center;
}

#drawboard .sidebar .sidebar-outer .sidebar-open-close-blk  {
  position: absolute;
  right: 0px;
  background: #ddd;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

#drawboard .sidebar .sidebar-outer .sidebar-title-blk {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

#drawboard .sidebar .sidebar-outer .sidebar-title-blk .title {
  font-family: ProximaNova-SemiBold;
  font-size: 28px;
  color: #000;
}

#drawboard .sidebar .sidebar-outer .steps-outer {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#drawboard .sidebar .sidebar-outer .step-blk {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 5px 0; */
  margin-bottom: 5px;
}

#drawboard .sidebar .sidebar-outer .step-blk .step-number {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

#drawboard .sidebar .sidebar-outer .step-blk .step-number .span {
  font-family: ProximaNova-Regular;
  font-size: 20px;
  color: #000;
}

#drawboard .sidebar .sidebar-outer .step-blk .step-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

#drawboard .sidebar .sidebar-outer .step-blk .hr-line {
  width: 1px;
  height: 30px;
  background: #979797;
  margin: 5px 0;
}

#drawboard .sidebar .sidebar-outer .step-desc p {
  font-family: ProximaNova-Regular;
  font-size: 12px;
  color: #000;
  text-align: center;
}

#drawboard .drawboard-outer-blk {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#drawboard .drawboard-outer-blk .drawboard-header {
  height: 60px;
  background-color: #FFFFFF;
}

#drawboard .drawboard-outer-blk  #canvasblk {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  transition: margin-left .5s;
}

#canvasblk .canvas-outer {
  margin: 0 auto;
}

#canvasblk #canvasInAPerfectWorld {
  /* cursor: url(/public/assets/images/icons/Close.png), default; */
}

#canvasblk .canvas-outer .front-name-blk,
#canvasblk .canvas-outer .back-name-blk,
#canvasblk .canvas-outer #vr-line {
  position: absolute;
  text-align: center;
}

#canvasblk .canvas-outer .front-name-blk,
#canvasblk .canvas-outer .back-name-blk {
  background: #FFF;
}

#canvasblk .canvas-outer .front-name-blk .title, 
#canvasblk .canvas-outer .back-name-blk .title {
  font-size: 14px;
  font-family: ProximaNova-Bold;
}

#canvasblk .canvas-outer .front-name-blk .desc,
#canvasblk .canvas-outer .back-name-blk .desc {
  font-size: 10px;
  font-family: ProximaNova-Regular;
}

#canvasblk .canvas-outer #vr-line {
  height: 460px;
  width: 1px;
  background: #DDD;
}

#drawboard .drawboard-outer-blk .draw-footer-blk {
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

#drawboard .drawboard-outer-blk .draw-footer-blk .draw-footer-outer {
  display: flex;
}

#drawboard .draw-footer-outer .front-bottom,
#drawboard .draw-footer-outer .back-bottom {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#drawboard .draw-footer-outer .front-bottom {
  width: 50%;
  background: #EEE;
}

#drawboard .draw-footer-outer .back-bottom {
  width: 50%;
  background: #EEE;
}

#drawboard .draw-footer-outer .front-bottom p,
#drawboard .draw-footer-outer .back-bottom p {
  font-size: 12px;
  font-family: ProximaNova-Regular;
  margin-bottom: 10px;
}

#drawboard .draw-footer-outer .front-bottom .input-blk,
#drawboard .draw-footer-outer .back-bottom .input-blk {
  display: flex;
  justify-content: space-between;
}

#drawboard .draw-footer-outer .front-bottom .input-blk input,
#drawboard .draw-footer-outer .back-bottom .input-blk input {
  height: 30px;
  width: 125px;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0 5px;
  font-family: ProximaNova-Regular;
  background: transparent;
  text-align: center;
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 767px) and (max-width: 1024px) {  
  
  #drawboard .sidebar .sidebar-outer .sidebar-open-close-blk  {
    top: 40%;
  }

}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 768px) {

  #drawboard .sidebar .sidebar-outer .sidebar-title-blk .title {
    font-size: 22px;
  }

  #drawboard .sidebar .sidebar-outer {
    width: 85%;
  }

  #drawboard .drawboard-outer-blk {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #canvasblk .canvas-outer .front-name-blk .title, 
  #canvasblk .canvas-outer .back-name-blk .title {
    font-size: 10px;
  }

  #canvasblk .canvas-outer .front-name-blk .desc,
  #canvasblk .canvas-outer .back-name-blk .desc {
    font-size: 6px;
  }

  #drawboard .drawboard-outer-blk .draw-footer-blk {
    height: 160px;
  }

  #drawboard .drawboard-outer-blk .draw-footer-blk .draw-footer-outer {
    display: flex;
    flex-direction: column;
  }

  #drawboard .draw-footer-outer .front-bottom,
  #drawboard .draw-footer-outer .back-bottom {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  #drawboard .sidebar .sidebar-outer .sidebar-title-blk .title {
    font-size: 22px;
  }

  #drawboard .sidebar .sidebar-outer {
    width: 85%;
  }

  #drawboard .drawboard-outer-blk {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #canvasblk .canvas-outer .front-name-blk .title, 
  #canvasblk .canvas-outer .back-name-blk .title {
    font-size: 10px;
  }

  #canvasblk .canvas-outer .front-name-blk .desc,
  #canvasblk .canvas-outer .back-name-blk .desc {
    font-size: 6px;
  }

  #drawboard .drawboard-outer-blk .draw-footer-blk {
    height: 160px;
  }

  #drawboard .drawboard-outer-blk .draw-footer-blk .draw-footer-outer {
    display: flex;
    flex-direction: column;
  }

  #drawboard .draw-footer-outer .front-bottom,
  #drawboard .draw-footer-outer .back-bottom {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}