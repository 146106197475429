#signup-success {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

#signup-success .form-succes-outer-blk {
  width: 100%;
  height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#signup-success .form-succes-outer-blk .title {
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  font-family: ProximaNova-Bold;
}

#signup-success .desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#signup-success .desc p {
  font-size: 14px;
  color: #000000;
  font-family: ProximaNova-Regular;
  line-height: 22px;
}