#subscribe {
  width: 100%;
  height: 100%;
}

#subscribe .subscribe-outer {
  width: 100%;
  height: 100%;
}

#subscribe .subscribe-top-outer {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#subscribe .subscribe-top-inner {
  width: 75%;
}

#subscribe .subscribe-top-inner .title {
  font-family: ProximaNova-Bold;
  color: #333333;
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
}

#subscribe .subscribe-top-inner label {
  font-size: 13px;
  text-transform: capitalize;
  color: #333333;
  cursor: pointer;
}

#subscribe .subscribe-top-inner input {
	border: 1px solid #E3E3E3;
	border-radius: 4px;
  background-color: #FFFFFF;
  margin: 5px 0;
  padding: 10px 15px;
}

#subscribe .subscribe-top-inner .checkbox-label {
  display: flex;
  margin: 5px 0;
}

#subscribe .subscribe-top-inner .checkbox-label .label {
  margin-left: 10px;
}

#subscribe .subscribe-top-inner .checkbox-label .anonymous-label {
  color: #FF9A1C;
  cursor: default;
}

#subscribe .subscribe-top-inner .round input[type="checkbox"]:checked + label {
  background-color: #43DFEA;
  border-color: #43DFEA;
}

#subscribe .subscribe-top-inner .checkbox-label #subscribedEmail {
  margin-left: 10px;
}

#subscribe .subscribe-bottom {
  width: 100%;
  height: 25%;
  border-top: 1px solid #DDDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

#subscribe .subscribe-bottom .confirm-btn {
  padding: 10px 90px;
  border-radius: 4px;
  background-color: #64A543;
  color: #FFF;
  font-family: ProximaNova-Bold;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}
