#mycode {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

#mycode .mycode-outer-blk {
  width: 75%;
  margin-top: 10%;
  margin-bottom: 10%;
}

#mycode .mycode-blk {
  /* width:400px */
  height: 400px;
}

#mycode .mycode-blk .mycode-title {
  font-size: 26px;
  font-family: ProximaNova-Bold;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

#mycode .mycode-blk .code-form {
  display: flex;
  justify-content:space-between;
  align-items: center;
}

#mycode .mycode-blk .code-form input{
  border: 1px solid #DADADA;   
  border-radius: 4px;
  background-color: #FFFFFF;
  opacity: 0.4;
  color: #333333;
  font-family: ProximaNova-Regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 15px 25px;
}

#mycode .mycode-blk .code-form .generate-blk {
  background: #64A543;
  border: 1px solid #64A543;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: ProximaNova-Bold;
}

#mycode .mycode-blk .code-form .generate-btn {
  color: #FFF;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 25px;
}

.table-outer table {
  width: 100%;
  margin-top: 35px;
  border-spacing: 0px;
}

.table-outer table tr th,
.table-outer table tr td {
  border-bottom: 1px solid #444444;
}

.table-outer table tr td,
.table-outer table tr th {
  text-align: left;
  color: #464646;
  font-family: ProximaNova-Bold;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19.9px;
  padding: 8px 0;
}

.table-outer table tr td {
  color: #999;
  opacity: 1;
  font-family: ProximaNova-Regular;
}
.copy-clipboard {
  border-radius: 5px;
  border: none;
  background-color: #64A543;
  color: #ffffff;
  padding: 0.5rem;
}
.copy-clipboard:hover{
  background-color: #86C765;
}