#gallery {

}

#gallery .gallery-outer-blk {
  width: 100%;
  height: 100%;
  background: #373737;
}

#gallery .gallery-inner-blk {
  padding: 25px 0;
  /* width: 80%; */
  max-width: 1024px;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

#gallery .gallery-inner-blk .footer{
  position: absolute;
  bottom: 10;
  width: 100%;
}

#gallery .gallery-inner-blk .footer p{
  text-align: center;
  opacity: 0.75;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
}

#gallery .gallery-inner-blk .gallery-top-blk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk {
  font-family: ProximaNova-Bold;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 29px;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk .title-link {
  font-family: ProximaNova-Regular;
  color: #6DD400;
  font-size: 18px;
  line-height: 16px;
  text-decoration: underline;
}

#gallery .gallery-inner-blk .gallery-top-blk .filter-outer-blk {
  font-family: ProximaNova-Regular;
  font-size: 12px;
  width: 70%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  border-radius: 5px;
}

#gallery .filter-inner-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
}

#gallery .filter-inner-blk .filter-blk.teacher{
  width:75%;
}

#gallery .filter-inner-blk .search-blk,
#gallery .filter-inner-blk .filter-blk {
  width: 50%;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: capitalize;
}

#gallery .filter-inner-blk .filter-blk {
  border-right: 2px solid #DADADA;
}

#gallery .filter-inner-blk .filter-blk .hr-line {
  width: 1px;
  background: #DDD;
  height: 40%;
}

#gallery .filter-inner-blk .filter-blk input {
  width: 10%;
  font-size: 12px;
  font-family: ProximaNova-Regular;
  border-radius: 5px;
  text-align: center;
  border: none;
  padding: 5px;
  background: transparent;
}

#gallery .filter-inner-blk .filter-blk #code,
#gallery .filter-inner-blk .filter-blk #gender {
  width: 60px;
  background: url(/public/assets/images/icons/ic_dropdown2.svg) 105% / 37% no-repeat transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding: 5px 10px 5px 5px; */
}

#gallery .filter-inner-blk .filter-blk #teacherCode {
  width: 80px;
  background: url(/public/assets/images/icons/ic_dropdown2.svg) 105% / 27% no-repeat transparent;
}

#gallery .filter-inner-blk .filter-blk #location {
  width: 70px;
}

#gallery .filter-inner-blk .filter-blk select {
  border: none;
  background: transparent;
  font-size: 12px;
  font-family: ProximaNova-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#gallery .filter-inner-blk .filter-blk select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

#gallery .filter-inner-blk .filter-title {
  text-transform: uppercase;
  font-family: ProximaNova-Bold;
}

#gallery .filter-inner-blk .search-blk {
  cursor: pointer;
}

#gallery .filter-inner-blk .search-blk input {
  height: 40px;
  width: 85%;
  border: none;
  font-size: 11px;
  font-family: ProximaNova-Regular;
  border-radius: 5px;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row { 
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 100%;
  margin-bottom: 20px;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .result-found {
  color: #FFFFFF;
  font-family: ProximaNova-Regular;
  font-size: 12px;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk { 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option {
  border-radius: 16px;
  background-color: #FFFFFF;
  padding: 5px 15px;
  font-family: ProximaNova-Bold;
  text-transform: capitalize;
  margin: 0 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option .close-img {
  margin-left: 5px;
  cursor: pointer;
}

#gallery .selected-filter-option .label {
  padding-right: 5px;
  font-family: ProximaNova-Regular;
}

#gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .clear-filter {
  color: #F77976;
  font-family: ProximaNova-Bold;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
  margin-left: 10px;
}

#gallery .gallery-inner-blk .gallery-content-outer {
  width: 100%;
  padding: 20px 0;
}

#gallery .gallery-inner-blk .gallery-content-outer .banner-img {
  width: 100%;
  margin: 20px 0;
}

#gallery .gallery-inner-blk .gallery-content-blk {
  margin-top: 25px;
  display: inline-block;
  text-align: center;
}

#gallery .gallery-inner-blk .gallery-content-blk .mask-blk {
  width: 330px;
  height: 203px;
  margin: 5px;
  display: inline-block;
}

#gallery .mask-blk .mask-top {
  width: 100%;
  height: 80%;
  background: #eee;
  position: relative;
}

#gallery .mask-blk .mask-top .img-mask {
  width: 100%;
  height: 100%;
  background: lightgrey;
  opacity: 0.6;
}

#gallery .position-align {
  position: absolute;
  width: 330px;
}

#gallery .mask-blk .mask-top .select-and-share-blk .round input[type="checkbox"]:checked + label {
  background-color: #F16969;
  border-color: #F16969;
}

#gallery .mask-blk .mask-top .select-and-share-blk {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

#gallery .mask-blk .mask-top .select-and-share-blk .share-btn {
  background: #FFF;
  padding: 5px 10px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  color: #606060;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid #C5C5C5;
}

#gallery .mask-blk .mask-top .select-and-share-blk .share-btn:hover {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
}

#gallery .mask-blk .mask-top img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* #gallery .mask-blk .mask-top .my-loader{
  background-image: url(/public/assets/images/defaultImg.jpg);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
} */

#gallery .gallery-inner-blk .word-cloud-outer {
  height: 350px;
  padding-bottom:50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#gallery .mask-blk .mask-bottom {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 20%;
}

#gallery .mask-blk .mask-bottom .mask-info {
  font-family: ProximaNova-Regular;
  font-size: 12px;
  color: #373737;
}

#gallery .mask-blk .mask-bottom .download-img {
  cursor: pointer;
  width: 80%;
}

#gallery .gallery-inner-blk .showdownload-outer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 65px;
  background: rgba(255,27,27) !important;
  display: flex;
  justify-content: center;
  align-items: center
}

#gallery .gallery-inner-blk .showdownload-outer.show {
  display: flex;
}

#gallery .gallery-inner-blk .showdownload-outer.hide {
  display: none
}

#gallery .gallery-inner-blk .showdownload-inner {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center
}

#gallery .gallery-inner-blk .showdownload-outer .selected-count-title,
#gallery .gallery-inner-blk .showdownload-outer .selected-title {
  color: #FFFFFF;
  font-family: ProximaNova-Bold;
  text-shadow: 0 1px 0 0 rgba(0,0,0,0.15);
}

#gallery .gallery-inner-blk .showdownload-outer .selected-count-title {
  font-size: 22px;
}

#gallery .gallery-inner-blk .showdownload-outer .selected-title {
  font-size: 14px;
}

#gallery .gallery-inner-blk .showdownload-outer .hr-line {
  width: 1px;
  height: 100%;
  background: #A9A9A9;
}

#gallery .gallery-inner-blk .showdownload-inner .download-btn {
  background: #FFF;
  padding: 10px 15px;
  border: none;
}
 
#gallery .gallery-inner-blk .pagination-outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  height: 30px;
}

#gallery .gallery-inner-blk .pagination-outer .pagination-inner {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  font-family: ProximaNova-Regular; 
  font-size: 14px;
  text-transform: capitalize;
}

#gallery .gallery-inner-blk .pagination-outer .pagination-inner .previous-blk,
#gallery .gallery-inner-blk .pagination-outer .pagination-inner .next-blk {
  cursor: pointer;
}

#gallery .gallery-inner-blk .pagination-outer .pagination-inner .number-blk span {
  padding: 0 10px;
  cursor: pointer;
}

#gallery .gallery-inner-blk  .cloud-download{
  float: right;
  padding: 5px;
  background: white;
  justify-content: flex-start;
  display: flex;
}
.words-container{
  height: 100%;
  background: linear-gradient(330.85deg, #000000 0%, #4D4D4D 100%);
  padding-bottom:50px;
}
#gallery .gallery-inner-blk  .word-cloud-count{
  height: 360px;
  color: #ffffff;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.words-count-header{
  background-color: #000000;
  color: #fff;
  border: 1px solid #ddd6;
  border-radius: 5px 5px 0px 0px; 
  padding: 8px;
  text-align: center;
}
.words-count-list{
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
  color: #000000;
  border-radius: 5px;
  margin: 0;
}
.words-count-wrap{
  display: flex;
  flex-wrap: wrap;
}
.words-count-list .list-element{
  margin: 2px 1px;
  width: fit-content;
  color: #000000;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 4px 8px;
  transition: all 0.3s;
}
.words-count-list .list-element:hover{
  background-color: #e9e9e9;
  box-shadow: #ccc 5px 5px 13px -3px;
}
.words-count-list::-webkit-scrollbar {
  display: none;
  transition: all 0.3s;
}
.words-count-list:hover::-webkit-scrollbar {
  display: block;
}






/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 1024px) {
  #gallery .gallery-inner-blk .word-cloud-outer {
    flex-direction: row;
    width: 100%;
  }
  #gallery .gallery-inner-blk .words-container{
    width: 83%;
  }
  #gallery .gallery-inner-blk .word-cloud-count{
    margin-top: -2rem;
    width: 17%;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row {
    display:block !important;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk {
    text-align:center !important;
    margin-bottom: 20px;
  }
  
  #gallery .gallery-inner-blk .gallery-top-blk .filter-outer-blk {
    display: block !important;
    width: 100% !important;
  }

  #gallery .gallery-inner-blk {
    padding-top: 20px !important;
  }

  #gallery .filter-inner-blk{
    display: block !important;
  }

  #gallery .filter-inner-blk .search-blk, #gallery .filter-inner-blk .filter-blk {
    width: 100% !important;
    margin-bottom: 10px;
  }

  #gallery .filter-inner-blk .search-blk {
    position: relative;
  }

  #gallery .filter-inner-blk .search-blk img {
    position: absolute;
    left:6%;
  }

  #gallery .filter-inner-blk .search-blk input {
    padding-left:30px;
  }

  .clr {
    clear: both;
  }

  #gallery .gallery-inner-blk .gallery-content-outer {
    padding: 20px 0;
  }
  
  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row {
    margin:40px 0px 0px 5px;
    padding: 0px 10px;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .result-found {
    text-align: center;
    padding:10px 0px;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk {
    display: block;
    padding: 10px 0px;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option {
    display: inline-block;
    margin:5px;
  }

  #maskpreview .pre-mask-blk {
    width:100%;
  }

  #maskpreview .pre-mask-blk .mask-top {
    display: inline-block;
    height: auto;
  }
  
  #maskpreview .pre-mask-blk .mask-top .share-outer {
    left:0;
  }

  #gallery .gallery-inner-blk  .cloud-download{
    top:40px;
    position: relative;
  }
  #gallery .gallery-inner-blk .word-cloud-outer {
    margin-top: 4rem;
    flex-direction: row;
    width: 80%;
    margin-left: 10%;
  }
  #gallery .gallery-inner-blk .words-container{
    width: 83%;
  }
  #gallery .gallery-inner-blk .word-cloud-count{
    width: 17%;
    margin-top: -2rem;
    height: 360px;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

/* @media (min-width: 481px) and (max-width: 767px) {
  
} */

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row {
    display:block !important;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk {
    text-align:center !important;
    margin-bottom: 20px;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .filter-outer-blk {
    display: block !important;
    width: 100% !important;
  }

  #gallery .gallery-inner-blk {
    padding-top: 20px !important;
  }

  #gallery .filter-inner-blk {
    display: block !important;
  }

  #gallery .filter-inner-blk .search-blk, #gallery .filter-inner-blk .filter-blk {
    width: 100% !important;
    margin-bottom: 10px;
  }

  #gallery .filter-inner-blk .search-blk {
    position: relative;
  }

  #gallery .filter-inner-blk .search-blk img {
    position: absolute;
    left:6%;
  }

  #gallery .filter-inner-blk .search-blk input {
    padding-left:30px;
  }

  .clr {
    clear: both;
  }

  #gallery .gallery-inner-blk .gallery-content-outer {
    /* padding: 20px 0; */
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row {
    margin:40px 0px 0px 5px;
    padding: 0px 10px;
    flex-direction: column;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .result-found {
    text-align: center;
    padding:10px 0px;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk {
    display: block;
    padding: 10px 0px;
  }

  #gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option {
    display: inline-block;
    margin:5px;
  }

  #maskpreview .pre-mask-blk {
    width:100%;
  }

  #maskpreview .pre-mask-blk .mask-top {
    display: inline-block;
    height: auto;
  }

  #maskpreview .pre-mask-blk .mask-top .share-outer {
    left:0;
    top: -42px;
  }

  #maskpreview .pre-mask-blk .mask-top .share-blk {
    padding: 0 15px 0 0;
  }

  #maskpreview .pre-mask-blk .mask-top .share-blk .share-btn {
    display: flex;
  }

  #gallery .gallery-inner-blk .showdownload-inner {
    padding: 0 15px;
    width: unset;
  }

  #overlay .overlay-block.img {
    width: 100%;
    height: 38%;
  }

  #maskpreview .pre-mask-blk {
    height: unset;
  }

  #maskpreview .navigation-outer .navigation-blk {
    top: 35%;
  }

  #gallery .gallery-inner-blk .showdownload-outer {
    background: rgba(255,27,27)!important;
  }  

  #gallery .gallery-inner-blk  .cloud-download{
    top:40px;
    position: relative;
  }
  .cloud-mob{
    overflow-x: auto;
    width: 300px;
    height: 300px;
    text-align: center;
    position: absolute;
    right: 100px;
    left:30px;
    margin: 50px 0px;

}
 
}
@media (min-width: 320px) and (max-width: 767px) {
  /* For mobile phones: */
  #gallery .gallery-inner-blk .word-cloud-outer{
    flex-direction: column;
    margin: 5% 5% 0% 5%;
    padding-bottom: 0px;
    width: 80%;
    height: fit-content;
  }
  .words-container{
    width: 100%;
  }
  .word-cloud-count{
    width: 100%;
  }
  .words-count-header{
    padding: 10px;
  }
}

svg g text {
  fill: white !important;
  font-family: ProximaNova-Regular !important;
  /* width:1000 !important; */
  /* transition-duration: 1000 !important;  */
  font-size: 10, 60;
  font-style: normal;
  font-weight: normal;
  padding: 3;
  rotations: 2;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
  background: #f1f1f1aa;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  display: block;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}