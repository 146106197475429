#chg-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#chg-form .chg-outer-blk {
  width: 75%;
}

#chg-form .chg-blk {
  width:400px
}

#chg-form .chg-blk .chg-title {
  font-size: 26px;
  font-family: ProximaNova-Bold;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
}

#chg-form .chg-blk .vertical-field-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

#chg-form .chg-blk .vertical-field-group .label-input {
  width: 80%;
  position: relative;
}

#chg-form .chg-blk label {
  font-size: 12px;
  text-transform: capitalize;
  margin: 10px 0;
}

#chg-form .chg-blk .vertical-field-group textarea,
#chg-form .chg-blk .vertical-field-group input[type=text],
#chg-form .chg-blk .vertical-field-group input[type=password],
#chg-form .chg-blk .vertical-field-group input[type=number],
#chg-form .chg-blk .vertical-field-group select {
  width: 97%;
}

#chg-form .chg-blk textarea,
#chg-form .chg-blk input[type=text],
#chg-form .chg-blk input[type=password],
#chg-form .chg-blk input[type=number],
#chg-form .chg-blk select {
  border: 1px solid #ddd;
  padding: 15px 10px;
  border-radius: 5px;
  font-size: 12px;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  margin: 10px 0;
}

 #chg-form .chg-blk input:focus,
 #chg-form .chg-blk select:focus {
  outline: none;
}


#chg-form .bottom-section {
  display: flex;
  width: 93%;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
}  

#chg-form .bottom-section .submit-blk {
  display: inline-block;
  width: unset;
  height: unset;
  padding:10px 25px;
  text-align: center;
  margin: 0;
  cursor: pointer;
}

#chg-form .bottom-section .submit-blk .submit-btn{ 
  background-color: #64A543;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
}

#chg-form .bottom-section .cancel-blk {
  display: inline-block;
  width: unset;
  height: unset;
  padding:10px 25px;
  text-align: center;
  margin: 0;
  cursor: pointer;
}

#chg-form .bottom-section .cancel-blk .cancel-btn {
  color: #7F7F7F;
  text-transform: uppercase;
  font-size: 12px;
}