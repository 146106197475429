#landing {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.footer-outer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-outer .footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-outer .footer-inner p {
  opacity: 0.75;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 30px 0 0 150px;
}

.footer-outer .footer-inner .help-blk {
  box-sizing: border-box;
  height: 40px;
  width: 100px;
  border: 1px solid #FFFFFF;
  opacity: 0.5;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 25px;
}

.footer-outer .footer-inner .help-blk span {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: capitalize;
  margin-left: 10px;
}

#landingImg {
  background: url(/public/assets/images/landingBg.png) no-repeat 50% fixed;
  background-size: cover;
  display: flex;
  overflow: hidden;
  height: 100vh;
}

#landing .side-by-side {
  /* width: 50%;
  min-height: 100vh;
  display: flex;
  justify-content: center; */
  align-items: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

#landing .side-by-side.w-25 {
  width: 25%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  z-index: 1;
}

#landing .side-by-side.w-75 {
  width: 75%;
}

#landing #sub-div1 {
  height: auto;
  position: absolute;
}

#landing #sub-div1 .btn-blk label {
  width: 237px;
  height: 16px;
  font-family: ProximaNova-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 2px 2px 7px rgb(0 0 0 / 25%);
}

.draw-blk {
  margin: 230px 150px;
}

#landing #sub-div1 .or-blk {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.group {
  padding-top: 50px;
}

#landing #sub-div1 .or-blk p {
  margin: 0 5px;
  color: #E3E3E3;
  font-family: ProximaNova-Regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}

#landing #sub-div1 .icon-group {
  width: 100%;
  height: 50%;
  display: flex;
}

#landing #sub-div1 .border-bottom {
  border-bottom: 1px solid #818274;
}

#landing #sub-div1 .border-left {
  border-left: 1px solid #818274;
}

#landing #sub-div1 .icon-group .side-by-side-icon {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#landing #sub-div1 .icon-group .side-by-side-icon .icon-img {
  width: 50px;
  height: 50px;
}

#landing #sub-div1 .icon-group .side-by-side-icon .icon-text-blk {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-transform: capitalize;
}

#landing #sub-div1 .icon-group .side-by-side-icon .icon-text-blk .icon-count {
  font-size: 22px;
  font-family: ProximaNova-ExtraBold;
}

#landing #sub-div1 .icon-group .side-by-side-icon .icon-text-blk .icon-title {
  font-size: 10px;
  font-family: ProximaNova-Regular;
}

#landing #sub-div2 {
  border-radius: 10px;
  width: 95%;
  height: 615px;
  display: flex;
  justify-content: space-between;
}

#landing #sub-div2 .sub-div2-1 {
  width: 34%;
}

#landing #sub-div2 .sub-div2-1 .ourvision-blk {
  padding: 25px 20px;
  margin-top: 100px;
  box-sizing: border-box;
  position: absolute;
  width: 365px;
  height: 479px;
  background: rgba(0, 0, 0, 0.41);
  border: 2px solid #FFFFFF;
  border-radius: 10px;
}

#landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
  text-transform: capitalize;
  position: absolute;
  width: 317px;
  height: 71px;
  font-family: ProximaNova-Extrabold;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 71px;
  text-align: center;
  color: #00D462;
  text-shadow: 2px 2px 7px rgb(0 0 0 / 25%);
}

#landing #sub-div2 .sub-div2-2 {
  width: 64%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

#landing #sub-div2 .sub-div2-2 .drawmask-blk .h1line {
  position: absolute;
  width: 388px;
  height: 71px;

  font-family: ProximaNova-Extrabold;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 71px;
  /* identical to box height */

  text-align: center;

  /* #E3E3E3 */

  color: #E3E3E3;

  /* Drop Shadow */

  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}



#landing #sub-div2 .sub-div2-1 .ourvision-blk {
  padding: 25px 20px;
  box-sizing: border-box;
  position: absolute;
  width: 365px;
  height: 479px;
  background: rgba(0, 0, 0, 0.41);
  border: 2px solid #FFFFFF;
  border-radius: 10px;
}

#landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
  text-transform: uppercase;
  position: absolute;
  width: 317px;
  height: 71px;
  font-family: ProximaNova-Extrabold;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 40px;
  text-align: center;
  color: #00D462;
  text-shadow: 2px 2px 7px rgb(0 0 0 / 25%);
}

#landing #sub-div2 .sub-div2-1 .ourvision-blk p {
  width: 315px;
  height: 392px;
  padding-top: 100px;
  font-family: ProximaNova-Extrabold;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  /* text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25); */

  /* #E3E3E3 */

  color: #E3E3E3;
}

#landing #sub-div2 .sub-div2-2 {
  width: 60%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

#landing #sub-div2 .sub-div2-2 .drawmask-blk {
  position: absolute;
  width: 596px;
  height: 256px;
  border: 2px solid #00D462;
  /* border-bottom: none; */
  border-radius: 11px;
  margin-top: 100px;
}

.drawbutton-img {
  width: 330px;
}

#landing #sub-div2 .sub-div2-2 .drawmask-blk .h1line {
  position: absolute;
  width: 415px;
  height: 71px;

  font-family: ProximaNova-Extrabold;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 71px;
  /* identical to box height */

  text-align: center;

  /* #E3E3E3 */

  color: #E3E3E3;

  /* Drop Shadow */

  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

#landing #sub-div2 .sub-div2-2 .drawmask-blk .h2line {
  position: absolute;
  width: 519px;
  height: 71px;
  padding-top: 70px;
  font-family: ProximaNova-Extrabold;
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 71px;
  /* identical to box height */

  text-align: center;

  /* #E3E3E3 */

  color: #E3E3E3;

  /* Drop Shadow */

  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.draw-btn {
  text-decoration: none;
  cursor: pointer;
  /* border-radius: 5px; */
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  width: 304px;
  height: 52px;
  /* background: rgba(0, 212, 98, 1); */
  /* border: 2px solid #FFFFFF; */
  border-radius: 6px;
  justify-content: space-evenly;
}

.create-btn {
  text-decoration: none;
  cursor: pointer;
  display: none;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  width: 304px;
  height: 52px;
  background: rgba(0, 212, 98, 0.82);
  border: 2px solid #FFFFFF;
  border-radius: 6px;
  justify-content: space-evenly;
}


#landing #sub-div2 .sub-div2-2 .viewmask-blk {
  margin-top: 410px;
  position: absolute;
  width: 596px;
  height: 167px;
  border: 2px solid #E3E3E3;
  border-radius: 11px;
  /* border-bottom: none; */
}


#landing #sub-div2 .sub-div2-2 .viewmask-blk .number {
  font-size: 42px;
  font-family: ProximaNova-Extrabold;
  font-style: normal;
  color: #00D462;
  text-align: center;
  font-weight: 800;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

#landing #sub-div2 .sub-div2-2 .viewmask-blk .tag {
  text-transform: capitalize;
  color: #E3E3E3;
  text-align: center;
  font-family: ProximaNova-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

#landing #sub-div2 #join-blk>p {
  margin: 50px 0;

}

#landing #sub-div2 #join-blk .btn-blk {
  margin: 20px 0;
  display: flex;
}

#landing #sub-div2 #join-blk .or-blk {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#landing #sub-div2 #join-blk .or-blk p {
  margin: 0 5px;
  width: 18px;
  height: 19px;
  font-family: ProximaNova-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #E3E3E3;
}

.h-line {
  width: 117px;
  height: 0px;
  border: 1px solid #E3E3E3;
  transform: rotate(180deg);
}

.h1-line1 {
  height: 1px;
  width: 100%;
  background: #00D462;
  margin: 54px 2px;
}

.viewtext-blk {
  display: flex;
  justify-content: space-evenly;
  margin-top: 18px;
}

.view-btn {
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 12px 230px;
}

.react-player {
  width: 317px;
  height: 177px;
}

#landing #sub-div2 #group .btn-blk {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
}

.h1-line2 {
  height: 1px;
  width: 100%;
  background: white;
  margin: 0px 2px 0px 3px;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 1150px) and (max-width: 1350px) {

  #landingImg {
    height: 100%;
  }

  #landing #sub-div2 {
    display: flex;
    justify-content: space-between;
    width: 95%;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    position: unset;
    box-sizing: border-box;
    width: 265px;
    height: 379px;
    background: rgba(0, 0, 0, 0.41);
    border: 2px solid #FFFFFF;
    border-radius: 10px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
    width: 220px;
    height: 51px;
    font-family: ProximaNova-Bold;
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 31px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk p {
    width: 220px;
    height: 392px;
    padding-top: 70px;
    font-family: ProximaNova-Extrabold;
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 20px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk {
    width: 486px;
    height: 196px;
    border: 2px solid #00D462;
  }

  .drawbutton-img {
    width: 250px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h1line {
    font-size: 35px;
    line-height: 35px;
    width: 280px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h2line {
    width: 350px;
    font-size: 35px;
    line-height: 5px;
  }

  .draw-blk {
    margin: 170px 145px;
  }

  .draw-btn {
    width: 204px;
  }

  .create-btn {
    width: 204px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk {
    margin-top: 345px;
    width: 486px;
    height: 127px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .number {
    font-size: 35px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .tag {
    font-size: 15px;
  }

  .viewtext-blk {
    margin-top: 10px;
  }

  .draw-btn p {
    font-size: 13px;
  }

  .create-btn p {
    font-size: 13px;
  }

  .gallery-btn p {
    font-size: 11px;
    width: 130px;
    height: 15px;
    padding: 2px;
  }

  .view-btn {
    margin: 5px 170px;
  }
}

@media (min-width: 800px) and (max-width: 1150px) {

  #landingImg {
    height: 100%;
  }

  .react-player {
    width: 317px;
    height: 177px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    width: 195px;
    height: 279px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
    width: 147px;
    font-size: 24px;
    line-height: 25px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk p {
    width: 160px;
    height: 192px;
    padding-top: 50px;
    font-size: 15px;
    line-height: 15px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk {
    width: 356px;
    height: 146px;
  }

  .drawbutton-img {
    width: 200px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h1line {
    font-size: 22px;
    line-height: 60px;
    width: 190px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h2line {
    width: 240px;
    font-size: 22px;
    line-height: 0px;
  }

  .draw-blk {
    margin: 130px 115px;
  }

  .draw-btn {
    width: 130px;
    height: 35px;
  }

  .create-btn {
    width: 195px;
    height: 35px;
  }

  .draw-btn p {
    font-size: 10px;
  }

  .create-btn p {
    font-size: 10px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk {
    margin-top: 270px;
    width: 356px;
    height: 105px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .number {
    font-size: 20px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .tag {
    font-size: 9px;
  }

  .view-btn {
    margin: 8px 115px;
  }

  .gallery-btn p {
    width: 130px;
    font-size: 9px;
    padding: 0px;
  }

  .logo-blk .logo-img {
    width: 180px;
  }

  .react-player {
    width: 200px;
    height: 120px;
  }

  #landing #sub-div1 .btn-blk label {
    width: 200px;
    font-size: 9px;
  }

  .signin-btn {
    width: 200px;
    height: 42px;
  }

  #landing #sub-div1 .or-blk {
    width: 200px;
  }

  .footer-outer {
    display: none;
  }

  .signup-btn {
    width: 200px;
    height: 42px;
  }
}



@media (min-width: 480px) and (max-width: 800px) {
  #landingImg {
    height: 100%;
    display: block;
  }

  .footer-outer {
    display: none;
  }

  #landing .side-by-side {
    align-items: flex-start;
  }

  #landing .side-by-side.w-25 {
    width: 100%;
    height: 200vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  }

  #landing .side-by-side.w-75 {
    width: 100%;
    height: 200vh;
  }

  #landing #sub-div2 {
    display: flex;
    flex-direction: column;
    height: 1000px;
    justify-content: space-between;
    align-items: center;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    position: unset;
  }

  #landing #sub-div2 .sub-div2-1 {
    width: 38%;
  }

  #landing #sub-div2 .sub-div2-2 {
    width: 60%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 25px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    width: 225px;
    height: 329px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
    width: 187px;
    font-size: 30px;
    line-height: 31px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk p {
    width: 190px;
    height: 192px;
    padding-top: 60px;
    font-size: 16px;
    line-height: 19px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk {
    width: 356px;
    height: 146px;
    margin-top: 15px;
  }

  .drawbutton-img {
    width: 280px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h1line {
    font-size: 22px;
    line-height: 60px;
    width: 190px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h2line {
    width: 240px;
    font-size: 22px;
    line-height: 1px;
  }

  .draw-blk {
    margin: 129px 115px;
  }

  .create-blk {
    margin: 30px 10px 50px 5px;
  }

  .draw-btn {
    width: 130px;
    height: 35px;
  }

  .create-btn {
    display: flex;
  }

  .draw-btn p {
    font-size: 10px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk {
    margin-top: 220px;
    width: 356px;
    height: 105px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .number {
    font-size: 20px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .tag {
    font-size: 9px;
  }

  .view-btn {
    margin: 10px 120px;
  }

  .footer-outer {
    display: none;
  }

  .gallery-btn p {
    width: 120px;
    font-size: 9px;
    padding: 0px;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 380px) and (max-width: 480px) {
  #landing #sub-div2 .sub-div2-2 {
    width: 73%;
    margin-top: 20px;
  }
}

@media (min-width: 340px) and (max-width: 380px) {
  #landing #sub-div2 .sub-div2-2 {
    width: 90%;
    margin-top: 20px;
  }
}


@media (min-width: 320px) and (max-width: 340px) {
  #landing #sub-div2 .sub-div2-2 {
    width: 103%;
    margin-top: 20px;
  }

  #landing #sub-div2 .sub-div2-1 {
    height: 100vh;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  #landingImg {
    height: 100%;
    display: block;
  }

  #landing .side-by-side {
    align-items: flex-start;
  }

  #landing .side-by-side.w-25 {
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  }

  #landing .side-by-side.w-75 {
    width: 100%;
  }

  .footer-outer {
    display: none;
  }

  #landing #sub-div2 {
    display: flex;
    flex-direction: column;
    height: 1000px;
    justify-content: space-between;
    align-items: center;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    position: unset;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    width: 250px;
    height: 350px;
    margin-top: 50px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
    width: 187px;
    font-size: 25px;
    line-height: 31px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk p {
    width: 190px;
    height: 192px;
    padding-top: 60px;
    font-size: 16px;
    line-height: 19px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk {
    width: 310px;
    height: 146px;
    margin-top: 15px;
  }

  .drawbutton-img {
    width: 230px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h1line {
    font-size: 22px;
    line-height: 61px;
    width: 190px;
  }

  #landing #sub-div2 .sub-div2-2 .drawmask-blk .h2line {
    width: 240px;
    font-size: 22px;
    line-height: 1px;
  }

  .draw-blk {
    margin: 130px 105px;
  }

  .create-blk {
    margin: 30px 10px 50px 5px;
  }

  .draw-btn {
    width: 100px;
    height: 35px;
  }

  .create-btn {
    display: flex;
  }

  .draw-btn p {
    font-size: 8.5px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk {
    margin-top: 220px;
    width: 310px;
    height: 105px;
  }

  #landing #sub-div2 .sub-div2-1 {
    width: auto;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .number {
    font-size: 20px;
  }

  #landing #sub-div2 .sub-div2-2 .viewmask-blk .tag {
    font-size: 9px;
  }

  .view-btn {
    margin: 10px 100px;
  }

  .gallery-btn p {
    width: 100px;
    font-size: 8px;
    padding: 0px;
  }
}

@media (max-height: 480px) and (orientation:landscape) {
  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    width: 250px;
    height: 350px;
    margin-top: 50px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
    width: 187px;
    font-size: 25px;
    line-height: 31px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk p {
    width: 190px;
    height: 192px;
    padding-top: 60px;
    font-size: 16px;
    line-height: 19px;
  }
}

/* @media (max-height: 390px) and (orientation:landscape) {
  #landing .side-by-side.w-25 {
    width: 100%;
    height: 600px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk {
    width: 250px;
    height: 350px;
    margin-top: 50px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk h1 {
    width: 187px;
    font-size: 25px;
    line-height: 31px;
  }

  #landing #sub-div2 .sub-div2-1 .ourvision-blk p {
    width: 190px;
    height: 192px;
    padding-top: 60px;
    font-size: 16px;
    line-height: 19px;
  }
} */

@media (min-height: 320px) and (max-height: 480px) {
  #landing .side-by-side.w-25 {
    height: 200vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  }


  #landing #sub-div2 .sub-div2-2 {
    width: 49%;
  }

  #landing #sub-div2 .sub-div2-1 {
    width: 31%;
  }
}

@media (min-width: 320px) and (min-height: 640px) and (max-width: 450px) and (max-height: 980px) {
  #landing .side-by-side.w-25 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  }

  .footer-outer {
    display: none;
  }
}

@media (min-height: 480px) and (max-height: 980px) {
  #landing .side-by-side.w-25 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    height: 100vh;
  }

  .footer-outer {
    display: none;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  #landing .side-by-side.w-25 {
    height: 150vh;
  }
}

@media (max-height: 320px) and (max-width: 568px) {
  #landing .side-by-side.w-25 {
    height: 230vh;
  }
}