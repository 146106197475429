#signup-form { 
  width: 750px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  padding: 50px;
}

#signup-form .signup-outer-blk {
  width: 100%;
}

#signup-form .signup-outer-blk .back-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
}

#signup-form .signup-outer-blk .back-blk p {
  text-transform: uppercase;
  font-size: 12px;
  color: #666666;
}

#signup-form .signup-outer-blk .back-blk .arrow-black-left {
  height: 18px;
}

#signup-form .signup-blk {
  margin-top: 30px; 
}

#signup-form .signup-blk .title-blk {
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

 #signup-form .signup-blk .signup-title {
  font-size: 26px;
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
}

#signup-form .signup-blk .signup-desc,
#signup-form .sub-desc {
  font-size: 14px;
  font-family: ProximaNova-Regular;
  margin: 10px 0;
}

#signup-form .signup-blk .horizontal-field-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#signup-form .signup-blk .horizontal-field-group .label-input {
  width: 47%;
  position: relative;
}

#signup-form .signup-blk .vertical-field-group .label-input {
  width: 100%
}

#signup-form .signup-blk label {
  font-size: 12px;
  text-transform: capitalize;
  margin: 10px 0;
}

#signup-form .signup-blk .i-agree {
  display: flex;
}

#signup-form .signup-blk .horizontal-field-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


#signup-form .signup-blk .vertical-field-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/public/assets/images/icons/ic_dropdown.svg) 98% / 1.6% no-repeat transparent
}

#signup-form .signup-blk .horizontal-field-group textarea,
#signup-form .signup-blk .horizontal-field-group input[type=text],
#signup-form .signup-blk .horizontal-field-group input[type=password],
#signup-form .signup-blk .horizontal-field-group input[type=number],
#signup-form .signup-blk .horizontal-field-group select {
  width: 330px;
  /* width: 100%; */
}

#signup-form .signup-blk .vertical-field-group textarea,
#signup-form .signup-blk .vertical-field-group input[type=text],
#signup-form .signup-blk .vertical-field-group input[type=password],
#signup-form .signup-blk .vertical-field-group input[type=number],
#signup-form .signup-blk .vertical-field-group select {
  width: 97%;
}

#signup-form .signup-blk textarea,
#signup-form .signup-blk input[type=text],
#signup-form .signup-blk input[type=password],
#signup-form .signup-blk input[type=number],
#signup-form .signup-blk select {
  padding: 15px 10px;
  border-radius: 4px;
  background: rgba(175, 175, 175, 0.22);
  border: 1px solid #000000;
  font-size: 12px;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  margin: 10px 0;
}

 #signup-form .signup-blk input:focus,
 #signup-form .signup-blk select:focus {
  outline: none;
}

#signup-form .signup-blk .round input[type="checkbox"]:checked + label {
  background-color: #43DFEA;
  border-color: #43DFEA;
}

.react-tel-input {
  margin: 10px 0;
  height: 45px;
}

.react-tel-input .form-control { 
  width: 100%;
  height: 45px;
  font-size: 12px;
  padding: 15px 10px 15px 45px;
  background: rgba(175, 175, 175, 0.22);
  border: 1px solid #000000;
  border-radius: 4px;
}

.react-tel-input .flag-dropdown {
  padding: 15px 0;
  background: rgba(175, 175, 175, 0.22);
  border: 1px solid #000000;
}

#signup-form .signup-blk .submit-section {
  display: flex;
  justify-content: center;
}
/*Media query*/
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  #teacher-on-board #outer-blk {
    display: block !important;
    height: auto !important;
  }

  #teacher-on-board #outer-blk #right-side-blk {
    width: 95% !important;
    height: auto !important;
    margin: 0 auto;
  }
  
  #signup-form {
    display: block !important;
  }

  #signup-form .signup-outer-blk {
    width:100% !important;
  }

  #signup-form .signup-blk .horizontal-field-group {
    display: block;
  }

  #signup-form .signup-blk .horizontal-field-group .label-input {
    width: 100%;
    display: block;
  }

  #signup-form .signup-blk .horizontal-field-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/public/assets/images/icons/arrow-black-down.png) 98% / 1.6% no-repeat transparent;
  }
  
  #signup-form .signup-blk .horizontal-field-group textarea, #signup-form .signup-blk .horizontal-field-group input[type=text], #signup-form .signup-blk .horizontal-field-group input[type=number], #signup-form .signup-blk .horizontal-field-group select {
    width: 90%;
    margin:10px auto;
    display: block;
  }

  #signup-form .signup-blk label {
    margin: 0 10px;
  }

  #signup-form .signup-blk .vertical-field-group textarea, #signup-form .signup-blk .vertical-field-group input[type=text], #signup-form .signup-blk .vertical-field-group input[type=number], #signup-form .signup-blk .vertical-field-group select {
    width: 89%;
    display: block;
    margin:10px auto;
  }

  #signup-form .signup-outer-blk .back-blk {
    display: block;
    padding:20px 10px 10px;
    width: auto;
  }

  #signup-form .signup-outer-blk .back-blk p {
    display: inline-block;
    margin-left: 10px;
  }

  #signup-form .signup-blk {
    padding: 10px;
    margin-top: 0px;
  }

  #signup-form .signup-blk .title-blk {
    margin-bottom: 20px;
  }

  #signup-form .signup-blk .signup-desc {
    margin-top: 20px;
  }

  .submit-section {
    text-align: center;
  }  

  .submit-blk {
    display: inline-block;
    width: unset;
    height: unset;
    padding:10px 25px;
    text-align: center;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  #teacher-on-board #outer-blk {
    display: block !important;
    height: auto !important;
  }

  #teacher-on-board #outer-blk #right-side-blk {
    width: 95% !important;
    height: auto !important;
    margin: 0 auto;
  }
  
  #signup-form {
    display: block !important;
  }

  #signup-form .signup-outer-blk {
    width:100% !important;
  }

  #signup-form .signup-blk .horizontal-field-group {
    display: block;
  }

  #signup-form .signup-blk .horizontal-field-group .label-input {
    width: 100%;
    display: block;
  }

  #signup-form .signup-blk .horizontal-field-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/public/assets/images/icons/arrow-black-down.png) 98% / 1.6% no-repeat transparent;
  }
  
  #signup-form .signup-blk .horizontal-field-group textarea, #signup-form .signup-blk .horizontal-field-group input[type=text], #signup-form .signup-blk .horizontal-field-group input[type=number], #signup-form .signup-blk .horizontal-field-group select {
    width: 90%;
    margin:10px auto;
    display: block;
  }

  #signup-form .signup-blk label {
    margin: 0 10px;
  }

  #signup-form .signup-blk .vertical-field-group textarea, #signup-form .signup-blk .vertical-field-group input[type=text], #signup-form .signup-blk .vertical-field-group input[type=number], #signup-form .signup-blk .vertical-field-group select {
    width: 89%;
    display: block;
    margin:10px auto;
  }

  #signup-form .signup-outer-blk .back-blk {
    display: block;
    padding:20px 10px 10px;
    width: auto;
  }

  #signup-form .signup-outer-blk .back-blk p {
    display: inline-block;
    margin-left: 10px;
  }

  #signup-form .signup-blk {
    padding: 10px;
    margin-top: 0px;
  }

  #signup-form .signup-blk .title-blk {
    margin-bottom: 20px;
  }

  #signup-form .signup-blk .signup-desc {
    margin-top: 20px;
  }

  .submit-section {
    text-align: center;
  }  

  .submit-blk {
    display: inline-block;
    width: unset;
    height: unset;
    padding:10px 25px;
    text-align: center;
  }

}