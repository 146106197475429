.Toastify {
  position: fixed !important;
  right: 0;
  top: 120px;
  z-index: 1000000;
}

.Toastify__toast {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: unset !important;
}

.Toastify__toast--default {
  background: transparent !important;
  margin-right: 0px;
  margin-top: 0px;
}

.Toastify__close-button--default {
  display: none;
  margin-top: 5px;
}

.Toastify__close-button {
  display: none;
  position: absolute!important;
  top: 0!important;
  right: 10px!important;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: .7;
  -webkit-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.Toastify__toast-container {
  right: 0 !important;
  z-index: 999999 !important;
}

#toaster-block {
  height: 94px;
  width: 300px;
  border-radius: 4px 0 0 4px;
  background-color: #FFFFFF;
}

#toaster-block .inner-blk {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#toaster-block .inner-blk img{
  margin-left: 10px;
}

#toaster-block .toaster-body {
  margin-left: 20px;
  font-family: ProximaNova-Regular;
  color: #333333;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}