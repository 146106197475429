#editprofile-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#editprofile-form .editprofile-outer-blk {
  width: 75%;
  height: 450px;
}

#editprofile-form .editprofile-blk .editprofile-title {
  font-size: 26px;
  font-family: ProximaNova-Bold;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
}

#editprofile-form .editprofile-blk .horizontal-field-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#editprofile-form .editprofile-blk .horizontal-field-group .label-input {
  width: 47%;
}

#editprofile-form .editprofile-blk label {
  font-size: 12px;
  text-transform: capitalize;
  margin: 10px 0;
}

#editprofile-form .editprofile-blk .horizontal-field-group textarea,
#editprofile-form .editprofile-blk .horizontal-field-group input[type=text],
#editprofile-form .editprofile-blk .horizontal-field-group input[type=password],
#editprofile-form .editprofile-blk .horizontal-field-group input[type=number],
#editprofile-form .editprofile-blk .horizontal-field-group select {
  width: 250px;
}

#editprofile-form .editprofile-blk textarea,
#editprofile-form .editprofile-blk input[type=text],
#editprofile-form .editprofile-blk input[type=password],
#editprofile-form .editprofile-blk input[type=number],
#editprofile-form .editprofile-blk select {
  border: 1px solid #ddd;
  padding: 15px 10px;
  border-radius: 5px;
  font-size: 12px;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  margin: 10px 0;
}

 #editprofile-form .editprofile-blk input:focus,
 #editprofile-form .editprofile-blk select:focus {
  outline: none;
}

#editprofile-form .editprofile-blk  .horizontal-field-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/public/assets/images/icons/ic_dropdown.svg) 94% / 7% no-repeat transparent;
}


#editprofile-form .editprofile-blk  .vertical-field-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/public/assets/images/icons/ic_dropdown.svg) 98% / 1.6% no-repeat transparent
}


#editprofile-form .bottom-section {
  display: flex;
  width: 400px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}  

#editprofile-form .bottom-section .submit-blk {
  display: inline-block;
  width: unset;
  height: unset;
  padding:10px 25px;
  text-align: center;
  margin: 0;
  cursor: pointer;
}

#editprofile-form .bottom-section .submit-blk .submit-btn{ 
  background-color: #64A543;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
}

#editprofile-form .bottom-section .cancel-blk {
  display: inline-block;
  width: unset;
  height: unset;
  padding:10px 25px;
  text-align: center;
  margin: 0;
  cursor: pointer;
}

#editprofile-form .bottom-section .cancel-blk .cancel-btn {
  color: #7F7F7F;
  text-transform: uppercase;
  font-size: 12px;
}

.react-tel-input {
  margin: 10px 0;
  height: 45px;
}

.react-tel-input .form-control { 
  width: 103%;
  height: 45px;
  font-size: 12px;
  padding: 15px 10px 15px 45px;

}

.react-tel-input .flag-dropdown {
  padding: 15px 0;
}