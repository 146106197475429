#header {
  background-image: url('/public/assets/images/headerBg.png');
}

#header .header-outer-blk {
  padding: 0 10px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .header-outer-blk .gallery-logo-blk {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header .header-outer-blk .gallery-logo-img {
  cursor: pointer;
  width: 120px;
}

#header .header-outer-blk .gallery-logo-blk .vr-line {
  width: 1px;
  height: 60px;
  background: #818274;
  margin: 0 10px;
}

#header .header-outer-blk .gallery-logo-blk p {
  color: #FFF;
}

#header .header-outer-blk .draw-btn {
  margin: 0;
}

#header .header-outer-blk .request-btn {
  box-sizing: border-box;
  height: 40px;
  width: 150px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  display:flex;
  justify-content:center;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
}

#header .header-outer-blk .request-btn p {
  text-transform: uppercase;
  color: #fff;
  line-height: 14px;
  font-size: 16px;
}

#header .header-right-btn-section .d-flex {
  display: flex;
  align-items: center;
}

.dropbtn {
  color: white;
  background-color:transparent;
  font-size: 14px;
  border: none;
  cursor: pointer;
  margin-right: 40px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.dropbtn img {
  margin-left: 10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  /* top: 30px; */
  width: 164px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(99,99,99,0.31);
}

.dropdown-content p {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #555555;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  cursor: pointer;
}

.dropdown-content p:hover {background-color: #64A543}

.dropdown:hover .dropdown-content {
  display: block;
}


#header .header-outer-blk input[type=text] {
  width: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 12px;
  background-color: transparent;
  background-image: url('/public/assets/images/icons/Search-large.png');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  animation-direction: alternate;
}

#header .header-outer-blk input[type=text]:focus {
  width: 100%;
}

/*Media query*/
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  #header .header-outer-blk {
    display:block;
    height: auto;
  }

  #header .header-outer-blk .gallery-logo-blk {
    width: 100%;
    margin-bottom:20px;
  }

  #header .header-right-btn-section {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
  }

}


/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  #header .draw-btn {
    width: unset;
    padding: 0 5px;
  }

  #header .draw-btn .arrow-white-right {
    display: none;
  }

  #header .draw-btn p {
    font-size: 10px;
    padding: 0 5px;
  }
}
