#maskpreview .navigation-outer {
  width: 100%;
  height: 100%;
}

#maskpreview .navigation-outer .navigation-blk { 
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 46%;
}

#maskpreview .navigation-outer .navigation-blk .navigation-btn {
  font-size: 16px;
  font-family: ProximaNova-Bold;
  background: #FFF;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

#maskpreview .navigation-outer .navigation-blk .navigation-btn.disable {
  visibility: hidden;
}

#maskpreview .pre-mask-blk {
  height: 450px;
  /* width: 800px; */
  width: 100%;
  margin: 0 auto;
}

#maskpreview .pre-mask-blk .mask-top {
  width: 100%;
  height: 87%;
  background: #eee;
}

#maskpreview .pre-mask-blk .mask-top .share-outer {
  position: absolute;
  width: 100%;
  /* left: -45px; */
}

#maskpreview .pre-mask-blk .mask-top .share-blk {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 15px 0 0;
}

#maskpreview .pre-mask-blk .mask-top .share-blk .share-btn {
  background: #FFF;
  padding: 5px 10px;
  text-align: center;
  border-radius: 15px;
  font-size: 10px;
  color: #606060;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid #C5C5C5;
}

#maskpreview .pre-mask-blk .mask-top .share-blk .share-btn:hover {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
}

#maskpreview .pre-mask-blk .mask-top img {
  width: 100%;
  height: 100%;
}

#maskpreview .pre-mask-blk .mask-bottom {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 13%;
  border-top: 1px solid #ddd; 
}

#maskpreview .pre-mask-blk .mask-bottom .mask-info {
  font-family: ProximaNova-Regular;
  font-size: 12px;
}

#maskpreview .pre-mask-blk .mask-bottom .download-img {
  cursor: pointer;
}