#download-header {
  background-image: url('/public/assets/images/headerBg.png');
}

#download-header .header-outer-blk {
  padding: 0 10px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#download-header .header-outer-blk .download-logo-blk {
  width: 20%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#download-header .header-outer-blk .download-logo-img {
  cursor: pointer;
  width: 150px;
  height: 36px;
}

#download-header .header-outer-blk .download-logo-blk .hr-line {
  width: 1px;
  height: 35px;
  background: #DDD; 
}

#download-header .header-outer-blk .download-logo-blk p {
  color: #FFF;
}

/*Media query*/
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  #download-header .header-outer-blk {
    display:block;
    height: auto;
  }
  
  #download-header .header-outer-blk .download-logo-blk {
    width: 100%;
    margin-bottom:20px;
  }
   
}


/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  #download-header .draw-btn {
    width: unset;
    padding: 0 5px;
  }

  #download-header .draw-btn .arrow-white-right {
    display: none;
  }
  
  #download-header .draw-btn p {
    font-size: 10px;
    padding: 0 5px;
  }
}