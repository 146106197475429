.educator-overlay-value-name{
  padding-left: 92px;
}
.educator-overlay-value-codes{
  padding-left: 200px;
}
.educator-overlay-value-name-value{
  padding-left: 97px;
}
.educator-overlay-value-codes-value{
  padding-left: 205px;
}
#educatorDetail .educatorDetail-blk .educatorDetail-title {
  margin-bottom: 30px;
  margin-top: 35px;
}

#educatorDetail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

#educatorDetail .educatorDetail-outer-blk {
  width: 75%;
  height: 100%;
  margin: 55px 0;
}

#educatorDetail .educatorDetail-blk {
  /* width:400px */
}

#educatorDetail .educatorDetail-blk .educatorDetail-title {
  font-size: 26px;
  font-family: ProximaNova-Bold;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
}

#educatorDetail .educatorDetail-blk .code-form {
  display: flex;
  justify-content:space-between;
  align-items: center;
}

#educatorDetail .educatorDetail-blk .code-form input{
  border: 1px solid #DADADA;
  border-radius: 4px;
  background-color: #FFFFFF;
  opacity: 0.4;
  color: #333333;
  font-family: ProximaNova-Regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 15px 25px;
}

#educatorDetail .educatorDetail-blk .code-form .generate-blk {
  background: #64A543;
  border: 1px solid #64A543;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: ProximaNova-Bold;
}

#educatorDetail .educatorDetail-blk .code-form .generate-btn {
  color: #FFF;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 25px;
}

.table-outer table {
  width: 100%;
  margin-top: 35px;
  border-spacing: 0px;
}

.table-outer table tr th,
.table-outer table tr td {
  border-bottom: 1px solid #444444;
}

.table-outer table tr td,
.table-outer table tr th {
  text-align: left;
  color: #464646;
  font-family: ProximaNova-Bold;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19.9px;
  padding: 8px 0;
}

.table-outer table tr td {
  color: #333333;
  opacity: 0.5;
  font-family: ProximaNova-Regular;
}
