#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: rgba(0,0,0,0.7);
  transition: .5s;
  z-index: 9;
}

#overlay .overlay-block { 
  background-color: #fff;
}

#overlay .overlay-block.img {
  height: 450px;
  width: 890px;
  background-color: transparent;
  position: relative;
}

#overlay .overlay-block.lg {
  width: 980px;
  height: 450px;
  position: relative;
}

#overlay .overlay-block.lg.EditProfile {
  width: 760px;
  height: 530px;
  position: relative;
  overflow-y: scroll;
}

#overlay .overlay-block.md.ChangePassword {
  width: 500px;
  height: 450px;
  position: relative;
}

#overlay .overlay-block.md.Help {
  width: 500px;
  height: auto !important;
  position: relative;
}

#overlay .overlay-block.md.MyCodes {
  width: 500px;
  margin-top: 100px;
  height: auto !important;
  position: relative;
  margin-bottom: 50px;
}

#overlay .overlay-block.md.EducatorDetail {
  width: 500px;
  height: 80% !important;
  position: relative;
}

#overlay .overlay-block.md {
  width: 500px;
  height: 320px;
  position: relative;
}

#overlay .overlay-block.sm {
  width: 900px;
  height: 400px;
  position: relative;
}

#overlay .overlay-body {
  position: relative;
  height: 100%;
  width: 100%;
}

#overlay .close-outer{
  right: 0;
  position: absolute;
}

#overlay .close-blk {
  z-index: 3;
  position: relative;
  right: -10px;
  top: -12px;
  background: #FFF;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 320px) and (max-width: 1024px) {
  
  #overlay .close-blk {
    position:static;
  } 

  #overlay .close-outer {
    position: absolute;
    right:0px;
    z-index: 2;
  }

}