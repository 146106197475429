#draw-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

#draw-header .header-outer-blk {
  padding: 0 10px;
  height: 60px;
  background-color: #FFFFFF;
  box-shadow: 0 6px 23px 0 rgba(0,0,0,0.18);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#draw-header .header-outer-blk .mobile-draw-submit-blk {
  display: none;
}

#draw-header .header-outer-blk .draw-logo-blk,
#draw-header .header-outer-blk .menu-blk,
#draw-header .header-outer-blk .draw-submit-blk {
  height: 60px;
  display: flex;
  align-items: center;
}

#draw-header .header-outer-blk .draw-logo-img {
  cursor: pointer;
  width: 100px;
  /* height: 36px; */
}

#draw-header .header-outer-blk .vr-line {
  width: 1px;
  height: 40px;
  background-color: #000;
  margin: 0 5px;
  opacity: 0.3;
}

#draw-header .header-outer-blk .draw-logo-blk,
#draw-header .header-outer-blk .draw-submit-blk {
  width: 20%;
}

#draw-header .header-outer-blk .menu-blk {
  background: #eee;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

#draw-header .header-outer-blk .menu-blk .tool-blk {
  height: 60px;
  display: flex;
  justify-content: space-around;
  border-right: 1px solid #ddd;
}

#draw-header .header-outer-blk .menu-blk .tool-blk .tool-img {
  height: 65%;
  margin: 0 20px;
  cursor: pointer;
}

#draw-header .header-outer-blk .menu-blk .tool-blk .tool-img.enable {
  height: 85%;
}

#draw-header .header-outer-blk .menu-blk .remove-blk {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ddd;
}

#draw-header .header-outer-blk .menu-blk .remove-blk .undo-img {
  height: 65%;
  cursor: pointer;
  margin: 0 5px;
}

#draw-header .header-outer-blk .menu-blk .size-blk { 
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#draw-header .header-outer-blk .menu-blk .size-blk .enable {
  background: #000;
}

#draw-header .header-outer-blk .menu-blk .size-blk #size-huge,
#draw-header .header-outer-blk .menu-blk .size-blk #size-large,
#draw-header .header-outer-blk .menu-blk .size-blk #size-normal,
#draw-header .header-outer-blk .menu-blk .size-blk #size-small {
  border: 1px solid gray;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 10px;
}

#draw-header .header-outer-blk .menu-blk .size-blk #size-huge {
  width: 18px;
  height: 18px;
}

#draw-header .header-outer-blk .menu-blk .size-blk #size-large {
  width: 12px;
  height: 12px;
}

#draw-header .header-outer-blk .menu-blk .size-blk #size-normal {
  width: 6px;
  height: 6px;
}

#draw-header .header-outer-blk .menu-blk .size-blk #size-small {
  width: 4px;
  height: 4px;
}

#draw-header .header-outer-blk .menu-blk .color-blk {
  height: 60px;
  border-left: 1px solid #ddd;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#draw-header .header-outer-blk .menu-blk .color-blk #color-picker{
  /* border: 3px solid #eee; */
}

#draw-header .header-outer-blk .menu-blk .color-blk .color-picker-btn {
  width: 36px;
  height: 10px;
  padding: 5px;
  border: 2px solid #fff;
  cursor: pointer;
  background: #000000;
}

#draw-header .header-outer-blk .menu-blk .color-blk .popover{
  position: absolute;
  top: 50px;
  left: -120px;
}

#draw-header .header-outer-blk .menu-blk .text-blk {
  height: 60px;
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: ProximaNova-Regular;
  padding: 0 10px;
}

#draw-header .header-outer-blk .menu-blk .text-blk select {
  border: 1px solid #ddd;
  background: #eee;
  padding: 5px;
  font-size: 10px;
  cursor: pointer;
}

#draw-header .header-outer-blk .menu-blk .text-blk #bold-btn, 
#draw-header .header-outer-blk .menu-blk .text-blk #italic-btn {
  width: 10px;
  height: 10px;
  padding: 5px 10px;
  font-size: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
  text-transform: uppercase;
  font-family: ProximaNova-regular;
}

#draw-header .header-outer-blk .menu-blk .text-blk #bold-btn.enable {
  font-family: ProximaNova-Bold;  
}

#draw-header .header-outer-blk .menu-blk .text-blk #italic-btn.enable {
  font-style: italic;
}

#draw-header .header-outer-blk .draw-submit-blk {
  justify-content: space-evenly;
}

#draw-header .header-outer-blk .draw-submit-blk p {
  cursor: pointer
}

#draw-header .header-outer-blk .draw-submit-blk .draw-submit-btn,
#draw-header .header-outer-blk .mobile-draw-submit-blk .draw-submit-btn {
  height: 40px;
  width: 130px;
  border-radius: 5px;
  font-family: ProximaNova-Bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

#draw-header .header-outer-blk .mobile-draw-submit-blk .text-move-blk {
  height: 40px;
  border: 1px solid #ddd;
  background: #64A543;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: ProximaNova-bold;
  padding: 0 10px;
  margin: 0 5px;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
}


#draw-header .header-outer-blk .draw-submit-blk .draw-submit-btn.disable,
#draw-header .header-outer-blk .mobile-draw-submit-blk .draw-submit-btn.disable,
#draw-header .header-outer-blk .mobile-draw-submit-blk .text-move-blk.disable {
  cursor: not-allowed;
  background-color: #CCCCCC;  
  pointer-events: none;
}

#draw-header .header-outer-blk .draw-submit-blk .draw-submit-btn.enable,
#draw-header .header-outer-blk .mobile-draw-submit-blk .draw-submit-btn.enable,
#draw-header .header-outer-blk .mobile-draw-submit-blk .text-move-blk.enable {
  cursor: pointer;
  background-color: #64A543;
  pointer-events: auto;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 767px) and (max-width: 1024px) {

  #draw-header .header-outer-blk .menu-blk .tool-blk {
    width: 26%;
  }

  #draw-header .header-outer-blk .menu-blk .tool-blk .tool-img {
    height: 45%;
    margin: 0 10px;
    cursor: pointer;
  }

  #draw-header .header-outer-blk .menu-blk .color-blk {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* #draw-header .header-outer-blk .menu-blk #color-picker {
    width: 200px;
    height: 40px
  } */

  #draw-header .header-outer-blk .menu-blk .tool-blk .tool-img.enable {
    height: 80%;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 768px) {

  #draw-header .header-outer-blk .menu-blk .tool-blk .tool-img {
    margin: 0 5px;
  }

  #draw-header .header-outer-blk .menu-blk .remove-blk .undo-img {
    margin: 0 0;
  }

  #draw-header .header-outer-blk .menu-blk .size-blk #size-huge,
  #draw-header .header-outer-blk .menu-blk .size-blk #size-large,
  #draw-header .header-outer-blk .menu-blk .size-blk #size-normal,
  #draw-header .header-outer-blk .menu-blk .size-blk #size-small {
    margin: 0 8px;
  }

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  

  #draw-header .header-outer-blk {
    padding: 0;
    width: 100%;
    height: 120px;
    box-shadow: 0 6px 23px 0 rgba(0,0,0,0.18);
    flex-direction: column;
    align-items: normal;
  }

  #draw-header .header-outer-blk .menu-blk {
    width: 100%;
  }

  #draw-header .header-outer-blk .menu-blk .tool-blk .tool-img {
    margin: 0 5px;
    height: 50%;
    cursor: pointer;
  }

  #draw-header .header-outer-blk .menu-blk .remove-blk .undo-img {
    height: 50%;
    cursor: pointer;
    margin: 0 5px;
  }

  #draw-header .header-outer-blk .menu-blk .size-blk #size-huge, 
  #draw-header .header-outer-blk .menu-blk .size-blk #size-large, 
  #draw-header .header-outer-blk .menu-blk .size-blk #size-normal, 
  #draw-header .header-outer-blk .menu-blk .size-blk #size-small {
    margin: 0 10px;
  }


  #draw-header .header-outer-blk .menu-blk .color-blk .color-picker-btn {
    width: 10px;
    height: 10px;
  }

  #draw-header .header-outer-blk .draw-logo-blk {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #draw-header .header-outer-blk .draw-submit-blk {
    display: none;
  }

  #draw-header .header-outer-blk .mobile-draw-submit-blk {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #draw-header .header-outer-blk .menu-blk .tool-blk .tool-img.enable {
    height: 70%;
  }
  
}
