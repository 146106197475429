#draw-preview {
  position: absolute;
  width: 100%;
  height: 100%;
}

#draw-preview .draw-preview-outer {
  width: 100%;
  height: 100%;
  display: flex;
}

#draw-preview .mask-outer-blk {
  width: 60%;
  height: 100%;
  background: rgba(255,27,27,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

#draw-preview .mask-blk {
  width: 90%;
  /* height: 70%; */
  height: 57%;
}

#draw-preview .mask-blk .mask-top {
  width: 100%;
  /* height: 82%; */
  height: 100%;
  background: #eee;
}

#draw-preview .mask-blk .mask-top .mask-img {
  width: 100%;
}

#draw-preview .mask-blk .mask-bottom {
  background: #EEE;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  height: 18%;
}

#draw-preview .metadata-blk {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#draw-preview .metadata-blk .metadata-form-blk {
  width: 70%;
}

#draw-preview .metadata-blk .metadata-form-blk .side-by-side {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#draw-preview .metadata-blk .metadata-form-blk .side-by-side .label-input {
  width: 48%;
  display: flex;
  flex-direction: column;
}

#draw-preview .metadata-blk .metadata-form-blk .side-by-side .label-input input,
#draw-preview .metadata-blk .metadata-form-blk .side-by-side .label-input select {
  width: 83%;
}

#draw-preview .metadata-blk .metadata-form-blk .side-by-side .label-input select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/public/assets/images/icons/arrow-black-down.png) 88% / 6% no-repeat transparent;
}

#draw-preview .metadata-blk .metadata-form-blk .side-by-side .label-input input[type=text] {
  width: 85%;
}

#draw-preview .metadata-blk .metadata-form-blk .label-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#draw-preview .metadata-blk .metadata-form-blk label {
  font-size: 12px;
  font-family: ProximaNova-Bold;
  color: #333333;
  text-transform: capitalize;
}

#draw-preview .metadata-blk .metadata-form-blk input[type=text],
#draw-preview .metadata-blk .metadata-form-blk input[type=number],
#draw-preview .metadata-blk .metadata-form-blk select {
  width: 92%;
  border: 1px solid #B2B2B2;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 12px;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  margin: 10px 0;
}

#draw-preview .metadata-blk .metadata-form-blk #gender {
  padding: 0 30px 0 10px !important;
  width: 90px !important;
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

#draw-preview .metadata-blk .metadata-form-blk input:focus,
#draw-preview .metadata-blk .metadata-form-blk select:focus {
 outline: none;
}

#draw-preview .metadata-blk .metadata-form-blk .title {
  font-family: ProximaNova-Bold;
  color: #333333;
  font-size: 20px;
  margin-bottom: 20px;
}

.metadata-btn-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.metadata-btn-blk .submit-btn,
.metadata-btn-blk .cancel-btn {
  border-radius: 5px;
  text-transform: uppercase;
  font-family: ProximaNova-Bold;
  font-size: 12px;
  padding: 10px 20px;  
  cursor: pointer;
}

.metadata-btn-blk .submit-btn {
  background-color: #64A543;
  color: #FFF;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.metadata-btn-blk .cancel-btn {
	border: 1px solid #7F7F7F;
  color: #7F7F7F;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

/* Media */
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1023px) {

  #overlay .overlay-block.lg {
    height: 100% !important;
  }

  #draw-preview .draw-preview-outer {
    display: block;
  }

  #draw-preview .mask-outer-blk {
    width: 100%;
    height: 54%;
    display: flex;
    justify-content: center;
    align-items: center;
  }  

  #draw-preview .mask-blk {
    height: 80%;
    width: 80%;
  }

  #draw-preview .mask-blk .mask-top {
    width: 100%;
    height: 80%;
    background: #eee;
  }
  
  #draw-preview .mask-blk .mask-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 14%;
  }

  #draw-preview .metadata-blk {
    width: auto;
    display: block;
    padding:20px 10px;
    background:#fff;
    height: 46%;
  }
  
  #draw-preview .metadata-blk .metadata-form-blk {
    width:auto;
  }
 
  #draw-preview .metadata-blk .metadata-form-blk input[type=text], 
  #draw-preview .metadata-blk .metadata-form-blk input[type=number] {
    width: auto;
  }

  #draw-preview .metadata-blk .metadata-form-blk #gender,
  #draw-preview .metadata-blk .metadata-form-blk #age {
    width: 94% !important;
  }

  #draw-preview .metadata-blk .metadata-form-blk #genderOthers {
    width: 98% !important;
  }

  #draw-preview .metadata-blk .metadata-form-blk .side-by-side .label-input select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/public/assets/images/icons/arrow-black-down.png) 95% / 2.5% no-repeat transparent;
  }

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  #overlay .overlay-block.lg {
    height: 100% !important;
  }

  #draw-preview .draw-preview-outer {
    display: block;
  }

  #draw-preview .mask-outer-blk {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }  

  #draw-preview .mask-blk {
    height: 70%;
    width: 80%;
  }

  #draw-preview .mask-blk .mask-top {
    width: 100%;
    height: auto;
    background: #64A543;
    display: flex;
  }

  #draw-preview .mask-blk .mask-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20%;
  }

  #draw-preview .metadata-blk {
    width: auto;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px 10px;
    background:#fff;
  }

  #draw-preview .metadata-blk .metadata-form-blk {
    width:auto;
  }

  #draw-preview .metadata-blk .metadata-form-blk input[type=text], 
  #draw-preview .metadata-blk .metadata-form-blk input[type=number] {
    width: auto;
    height: 35px !important;
  }

  #draw-preview .metadata-blk .metadata-form-blk #gender,
  #draw-preview .metadata-blk .metadata-form-blk #age {
    width: 80% !important;
  }

  #draw-preview .metadata-blk .metadata-form-blk #genderOthers {
    width: 95% !important;
  }

  #draw-preview .metadata-blk .metadata-form-blk .side-by-side .label-input select {
    height: 35px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(/public/assets/images/icons/arrow-black-down.png) 95% / 2.5% no-repeat transparent;
  }

}