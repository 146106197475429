#teacher-on-board {
  width: 100%;
  height: 100%;
}

#teacher-on-board #outer-blk {
  /* The image used */
  background-image: url('/public/assets/images/signupBg.png');
  /* Full height */
  /* height: 1400px;  */
  min-height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: center;
}

#teacher-on-board #outer-blk .logo-blk {
  position: absolute;
  top: 30px;
  left: 40px;
  display: flex;
  align-items: center;
}

#teacher-on-board #outer-blk .logo-blk .logo-img {
  width: 130px;
}


#teacher-on-board #outer-blk #right-side-blk {
  /* width: 750px; */
  padding: 50px;
  margin-top: 100px;
} 

/*Media query*/
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  #teacher-on-board #outer-blk #right-side-blk {
    padding: 20px 0;
  }
  
}