#signin-form { 
  width: 500px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF;
  padding: 50px;
}

#signin-form .signin-outer-blk {
  /* width: 75%; */
  width: 100%;
}

#signin-form .signin-outer-blk .back-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
}

#signin-form .signin-outer-blk .back-blk p {
  text-transform: uppercase;
  font-size: 12px;
  color: #666666;
}

#signin-form .signin-outer-blk .back-blk .arrow-black-left {
  height: 18px;
}

#signin-form .signin-blk {
  margin-top: 30px; 
}

#signin-form .signin-blk .title-blk {
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

 #signin-form .signin-blk .signin-title {
  font-size: 26px;
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
}

#signin-form .signin-blk .signin-desc {
  font-size: 14px;
  font-family: ProximaNova-Regular;
  text-transform: uppercase;
  color: #797979;
  margin: 10px 0;
}

#signin-form .signin-blk .vertical-field-group .label-input {
  width: 100%;
  /* width: 50%; */
  position: relative;
}

#signin-form .signin-blk label {
  font-size: 12px;
  text-transform: capitalize;
  margin: 10px 0;
}

#signin-form .signin-blk .i-agree {
  display: flex;
}

#signin-form .signin-blk .horizontal-field-group input[type=text],
#signin-form .signin-blk .horizontal-field-group input[type=password] {
  width: 250px;
}

#signin-form .signin-blk .vertical-field-group input[type=text],
#signin-form .signin-blk .vertical-field-group input[type=password] {
  /* width: 50%; */
  width: 95%;
}

#signin-form .signin-blk input[type=text],
#signin-form .signin-blk input[type=password] {
  border: 1px solid #ddd;
  padding: 15px 10px;
  border-radius: 5px;
  font-size: 12px;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  margin: 10px 0;
}

 #signin-form .signin-blk input:focus {
  outline: none;
}

#signin-form .signin-blk .forgotPwd {
  text-align: right;
  /* width: 52%; */
  margin-top: 10px;

}
#signin-form .signin-blk .forgotPwd a {
  color: #AE263A;
  font-family: ProximaNova-Regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

#signin-form .signin-blk .submit-section {
  display: flex;
  justify-content: center;
}

/*Media query*/
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  #teacher-on-board #outer-blk {
    display: block !important;
    height: auto !important;
  }

  #teacher-on-board #outer-blk #right-side-blk {
    width: 95% !important;
    height: auto !important;
    margin: 0 auto;
  }
  
  #signin-form {
    display: block !important;
  }

  #signin-form .signin-outer-blk {
    width:100% !important;
  }
  
  #signin-form .signin-blk .horizontal-field-group textarea, #signin-form .signin-blk .horizontal-field-group input[type=text], #signin-form .signin-blk .horizontal-field-group input[type=number], #signin-form .signin-blk .horizontal-field-group select {
    width: 90%;
    margin:10px auto;
    display: block;
  }

  #signin-form .signin-blk label {
    margin: 0 10px;
  }

  #signin-form .signin-blk .vertical-field-group textarea, #signin-form .signin-blk .vertical-field-group input[type=text], #signin-form .signin-blk .vertical-field-group input[type=number], #signin-form .signin-blk .vertical-field-group select {
    width: 89%;
    display: block;
    margin:10px auto;
  }

  #signin-form .signin-outer-blk .back-blk {
    display: block;
    padding:20px 10px 10px;
    width: auto;
  }

  #signin-form .signin-outer-blk .back-blk p {
    display: inline-block;
    margin-left: 10px;
  }

  #signin-form .signin-blk {
    padding: 10px;
    margin-top: 0px;
  }

  #signin-form .signin-blk .title-blk {
    margin-bottom: 20px;
  }

  #signin-form .signin-blk .signin-desc {
    margin-top: 20px;
  }

  .submit-section {
    text-align: center;
  }  

  .submit-blk {
    display: inline-block;
    width: unset;
    height: unset;
    padding:10px 25px;
    text-align: center;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  #teacher-on-board #outer-blk {
    display: block !important;
    height: auto !important;
  }

  #teacher-on-board #outer-blk #right-side-blk {
    width: 95% !important;
    height: auto !important;
    margin: 0 auto;
  }
  
  #signin-form {
    display: block !important;
  }

  #signin-form .signin-outer-blk {
    width:100% !important;
  }

  #signin-form .signin-blk .horizontal-field-group textarea, #signin-form .signin-blk .horizontal-field-group input[type=text], #signin-form .signin-blk .horizontal-field-group input[type=number], #signin-form .signin-blk .horizontal-field-group select {
    width: 90%;
    margin:10px auto;
    display: block;
  }

  #signin-form .signin-blk label {
    margin: 0 10px;
  }

  #signin-form .signin-blk .vertical-field-group textarea, #signin-form .signin-blk .vertical-field-group input[type=text], #signin-form .signin-blk .vertical-field-group input[type=number], #signin-form .signin-blk .vertical-field-group select {
    width: 89%;
    display: block;
    margin:10px auto;
  }

  #signin-form .signin-outer-blk .back-blk {
    display: block;
    padding:20px 10px 10px;
    width: auto;
  }

  #signin-form .signin-outer-blk .back-blk p {
    display: inline-block;
    margin-left: 10px;
  }

  #signin-form .signin-blk {
    padding: 10px;
    margin-top: 0px;
  }

  #signin-form .signin-blk .title-blk {
    margin-bottom: 20px;
  }

  #signin-form .signin-blk .signin-desc {
    margin-top: 20px;
  }

  .submit-section {
    text-align: center;
  }  

  .submit-blk {
    display: inline-block;
    width: unset;
    height: unset;
    padding:10px 25px;
    text-align: center;
  }

}