/********************************************* Font styles inclusion - start*/

@font-face {
  font-family: ProximaNova-Regular;
  src: url(/public/assets/fonts/Proxima-nova/ProximaNova-Regular.ttf) format('truetype');
}
@font-face {
  font-family: ProximaNova-Light;
  src: url(/public/assets/fonts/Proxima-nova/ProximaNova-Light.ttf) format('truetype');
}
@font-face {
  font-family: ProximaNova-Thin;
  src: url(/public/assets/fonts/Proxima-nova/ProximaNova-Thin.ttf) format('truetype');
}
@font-face {
  font-family: ProximaNova-ExtraBold;
  src: url(/public/assets/fonts/Proxima-nova/ProximaNova-Extrabold.ttf) format('truetype');
}
@font-face {
  font-family: ProximaNova-Bold;
  src: url(/public/assets/fonts/Proxima-nova/ProximaNova-Bold.ttf) format('truetype');
}
@font-face {
  font-family: ProximaNova-SemiBold;
  src: url(/public/assets/fonts/Proxima-nova/ProximaNova-Semibold.ttf) format('truetype');
}

/********************************************* Font styles inclusion - end*/

body {
  margin: 0;
  padding: 0;
  font-family: ProximaNova-Regular;
}

#root {
  /* max-width: 1400px; */
  margin: 0 auto;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
}

.logo-blk {
  display: grid;
  justify-content: center;
}

.logo-blk .vr-line {
  height: 55px;
  width: 1px;
  margin: 0 18px;
  background: #818274;
}

.logo-blk .logo-img {
  width: 320px;
}

.signup-btn,
.signin-btn {
  text-decoration: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 50px;
}

.draw-btn .mask-img {
  width: 28px;
}

.draw-btn .arrow-white-right {
  width: 7px;
}

.draw-btn p {
  position: absolute;
  width: 134px;
  height: 19px;
  font-family: ProximaNova-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #EEEEEE;
  text-shadow: 2px 2px 1px rgb(0 0 0 / 25%);
}

.create-btn p {
  position: absolute;
  width: 134px;
  height: 19px;
  font-family: ProximaNova-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #EEEEEE;
  text-shadow: 2px 2px 1px rgb(0 0 0 / 25%);
}

.signin-btn {
  justify-content: center;
  box-sizing: border-box;
  /* position: absolute; */
  width: 304px;
  height: 52px;
  background: #E3E3E3;
  border: 1px solid #60A550;
  border-radius: 6px;
  box-sizing: border-box;
  /* position: absolute; */
  width: 304px;
  height: 52px;
  background: #E3E3E3;
  border: 1px solid #60A550;
  border-radius: 6px;
}

.signin-btn p {  
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
  font-size: 14px;
  color: #60A550;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.signup-btn {
  justify-content: center;
    box-sizing: border-box;
      box-sizing: border-box;
    width: 304px;
    height: 54px;
    height: 52px;
    background: #464646;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    background: #464646;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
}

.signup-btn p {  
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
  font-size: 14px;
  color: #E3E3E3;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.gallery-btn {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
}

.hand {
  cursor: pointer;
}

.gallery-btn p {
  /* position: absolute; */
  width: 149px;
  height: 19px;
  border: 2px solid #FFFFFF;
  border-radius: 6px;
  font-family: ProximaNova-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 19px;
  border-radius: 6px;
  /* identical to box height */
  padding: 4px;
  text-align: center;
  /* text-decoration-line: underline; */
  
  /* #E3E3E3 */
  
  color: #E3E3E3;
  
  /* Drop Shadow */
  
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  bottom: -4px;
}


.submit-blk {
  background: #64A543;
  width: 120px;
  height: 50px;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: ProximaNova-Bold;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.download-btn {
  border: 1px solid #979797;
  color: #000;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

.download-btn span {
  padding-left: 5px;
}

.download-img {
  width: 18%;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

textarea {
  resize: none;
}

select::-ms-expand {	
  display: none; 
}

.hide {
  display: none !important;
}

/* checkbox start */
.round {
  position: relative;
  margin-right: 10px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 3px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 8px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/* checkbox end */

/* input number disapper arrow */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

/* loader start */
#loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  z-index: 2;
  overflow: hidden;
  display: none;
}

#loader-img {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 11px solid #3498db;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* loader end */

/* section disable */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
/* section disable end*/


/* Phone */
.react-tel-input {
  margin: 10px 0;
  height: 45px;
}

.react-tel-input .form-control { 
  width: 100%;
  height: 45px;
  font-size: 12px;
  padding: 15px 10px 15px 45px;

}

.react-tel-input .flag-dropdown {
  padding: 15px 0;
  background: rgba(175, 175, 175, 0.22);
border: 1px solid #000000;
}

.pwdShow {
  position: absolute;
  top: 42px;
  right: 10px;
  cursor: pointer;
}

select.decorated option:hover {
  background: #64A543;
}

.admin-console {
  color: #FFFFFF;
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 40px;
  margin-left: 25px;
}

.hidden {
  display: none;
}

