 

#admin-gallery .gallery-outer-blk {
  width: 100%;
  height: 100%;
  background: #373737;
}

#admin-gallery .gallery-inner-left-blk {
  float: left;
  width: 80%;
  height: 100%;
}
 
#admin-gallery .gallery-inner-right-blk {
  float: right;
  width: 20%;
  height: 100%;
  background-color: #2D2D2D;
}
#admin-gallery .gallery-inner-right-blk-topdiv{
  padding-top: 50px;
  padding-right: 40px;
  padding-left: 15px;
  padding-bottom: 40px;
  color: #FFFFFF;
  letter-spacing: 0;
}
#admin-gallery .gallery-inner-right-blk-topdiv-lfloat{
  float: left;
  font-size: 16px;
  color: white;
}

#admin-gallery .gallery-inner-right-blk-topdiv-name-rfloat{
  float: left;
  font-size: 11px;
  color: white;
  cursor: pointer;
}
#admin-gallery .gallery-inner-right-blk-topdiv-code-rfloat{
  float: right;
  font-size: 11px;
  color: white;
  cursor: pointer;
}
#admin-gallery .gallery-inner-right-blk-topdiv-phrase-name-rfloat{
  float: left;
  font-size: 11px;
  color: white;
}
#admin-gallery .gallery-inner-right-blk-topdiv-phrase-code-rfloat{
  float: right;
  font-size: 11px;
  color: white;
}

#admin-gallery .gallery-inner-right-blk-topdiv-rfloat {
  float: right;
  font-size: 12px;
  color: #6DD400;
  text-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
  border-bottom: 1px solid #6DD400;
  margin-left: 10px;
  cursor: pointer;

}
 
#admin-gallery .gallery-inner-right-blk-topdiv-table{
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  font-size: 12px;
  color: #C5C5C5;
  line-height: 16.9px;
}
#admin-gallery .gallery-inner-right-blk-topdiv-table-head{
  color: #D8D8D8;;
  background-color: rgb(82, 80, 80);
  border-bottom: 3px solid white;
  

}
#admin-gallery .gallery-inner-right-blk-topdiv-span
{
  padding-left : 17px;
  padding-bottom: 10px;
  font-size: 14px;
  color : white;
}

#admin-gallery .gallery-inner-right-blk-topdiv-table-head-cell{
padding-left : 5px;
padding-right: 5px;
padding-top: 10px;
padding-bottom: 5px;
border-bottom: 1px solid whitesmoke;
}

#admin-gallery .gallery-inner-blk {
  padding: 25px 0;
  /* width: 80%; */
  max-width: 1024px;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

#admin-gallery .gallery-dashboard-filter-dash{
  color: #FFFFFF;
  letter-spacing: 0;
  font-family: ProximaNova-Bold;
  font-size: 16px;
  padding-right: 25px;
}
 
.gallery-dashboard-filter-front,
.gallery-dashboard-filter-back{
  float: left;
  margin-top: 1px;
  margin-right: 0px;
  margin-left: 12px;
  margin-bottom: 15px;
  border-bottom: 2px solid  #2D2D2D;
  border-top: 0px none;
  border-left: 0px none;
  border-right: 0px none;
  background-color: #2D2D2D;
  color: #969696;
  cursor: pointer;
  font-weight: bold;
}

.gallery-dashboard-filter-front:hover,
.gallery-dashboard-filter-back:hover,
.gallery-dashboard-filter-front.active,
.gallery-dashboard-filter-back.active {
  border-bottom: 2px solid #64A543;
  box-sizing: border-box;
  color:  #FFFFFF;
}


#admin-gallery .gallery-dashboard-filter {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

#admin-gallery .gallery-dashboard-filter-today ,
#admin-gallery .gallery-dashboard-filter-month ,
#admin-gallery .gallery-dashboard-filter-week ,
#admin-gallery .gallery-dashboard-filter-year {
  margin-left: 25px;
  color: #969696;
  border-bottom: 2px solid #373737;
  cursor: pointer;
}

#admin-gallery .gallery-dashboard-filter-today:hover,
#admin-gallery .gallery-dashboard-filter-month:hover ,
#admin-gallery .gallery-dashboard-filter-week:hover ,
#admin-gallery .gallery-dashboard-filter-year:hover,
#admin-gallery .gallery-dashboard-filter-today.active,
#admin-gallery .gallery-dashboard-filter-month.active ,
#admin-gallery .gallery-dashboard-filter-week.active ,
#admin-gallery .gallery-dashboard-filter-year.active {
  border-bottom: 2px solid #64A543;
  box-sizing: border-box;
  color:  #FFFFFF;
}

#admin-gallery  .gallery-dashboard-filter-cus {
  margin-left: 25px;
  padding: 5px 10px;
  color: #969696;
  border: 1px solid rgba(218,218,218,0.12);
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

#admin-gallery  .gallery-dashboard-filter-cus.active {
  border: 1px solid #64A543;
  color: white;
}

#admin-gallery  .gallery-dashboard-filter-cus .DatePicker-blk {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1;
}

#admin-gallery  .gallery-dashboard-filter-cus .DatePicker-blk.show {
  display: block;
}

#admin-gallery  .gallery-dashboard-filter-cus .DatePicker-blk.hide {
  display: none;
}
 
.rdrCalendarWrapper {
  box-shadow: 0 2px 8px 0 rgba(99,99,99,0.31);
}

.rdrCalendarWrapper,
.rdrDateDisplayWrapper,
.rdrMonthsVertical {
  border-radius: 5px;
}

#admin-gallery  .gallery-dashboard-filter-today , .gallery-dashboard-filter-month , .gallery-dashboard-filter-week , .gallery-dashboard-filter-year , .gallery-dashboard-filter-cus
{
  font-size: 14px;
}
#admin-gallery .gallery-inner-blk .footer{
  position: absolute;
  bottom: 10;
  width: 100%;
}

#admin-gallery .gallery-inner-blk .footer p{
  text-align: center;
  opacity: 0.75;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: -10px;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk {
  font-family: ProximaNova-Bold;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 29px;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk .title-link {
  font-family: ProximaNova-Regular;
  color: #6DD400;
  font-size: 18px;
  line-height: 16px;
  text-decoration: underline;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .filter-outer-blk {
  font-family: ProximaNova-Regular;
  font-size: 6px;
  width: 70%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  border-radius: 5px;
}

#admin-gallery .filter-inner-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
}

#admin-gallery .filter-inner-blk .filter-blk.TD{
  width:75%;
}

#admin-gallery .filter-inner-blk .search-blk,
#admin-gallery .filter-inner-blk .filter-blk {
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: capitalize;
}

#admin-gallery .filter-inner-blk .filter-blk {
  border-right: 2px solid #DADADA;
}

#admin-gallery .filter-inner-blk .filter-blk .hr-line {
  width: 1px;
  background: #DDD;
  height: 40%;
}

#admin-gallery .filter-inner-blk .filter-blk input {
  width: 10%;
  font-size: 12px;
  font-family: ProximaNova-Regular;
  border-radius: 5px;
  text-align: center;
  border: none;
  padding: 5px;
  background: transparent;
}

#admin-gallery .filter-inner-blk .filter-blk #code,
#admin-gallery .filter-inner-blk .filter-blk #gender {
  width: 60px;
  background: url(/public/assets/images/icons/ic_dropdown2.svg) 105% / 37% no-repeat transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding: 5px 10px 5px 5px; */
}

#admin-gallery .filter-inner-blk .filter-blk #teacherCode {
  width: 80px;
  background: url(/public/assets/images/icons/ic_dropdown2.svg) 105% / 27% no-repeat transparent;
}

#admin-gallery .filter-inner-blk .filter-blk #location {
  width: 70px;
}

#admin-gallery .filter-inner-blk .filter-blk select {
  border: none;
  background: transparent;
  font-size: 12px;
  font-family: ProximaNova-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#admin-gallery .filter-inner-blk .filter-blk select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

#admin-gallery .filter-inner-blk .filter-title {
  color: #333333;
  font-family: "Proxima Nova";
  font-size: 11px;
}

#admin-gallery .filter-inner-blk .search-blk {
  cursor: pointer;
}

#admin-gallery .filter-inner-blk .search-blk input {
  height: 35px;
  width: 85%;
  border: none;
  font-size: 11px;
  font-family: ProximaNova-Regular;
  border-radius: 5px;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row { 
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 100%;
  /* margin-bottom: 20px;
  margin-top: 20px; */
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .result-found {
  color: #FFFFFF;
  font-family: ProximaNova-Regular;
  font-size: 12px;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk { 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option {
  border-radius: 16px;
  background-color: #FFFFFF;
  padding: 5px 15px;
  font-family: ProximaNova-Bold;
  text-transform: capitalize;
  margin: 0 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option .close-img {
  margin-left: 5px;
  cursor: pointer;
}

#admin-gallery .selected-filter-option .label {
  padding-right: 5px;
  font-family: ProximaNova-Regular;
}

#admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .clear-filter {
  color: #F77976;
  font-family: ProximaNova-Bold;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
  margin-left: 10px;
}

#admin-gallery .gallery-inner-blk .gallery-content-outer {
  width: 100%;
  /* padding: 20px 0; */
}

#admin-gallery .gallery-inner-blk .gallery-content-outer .banner-img {
  width: 100%;
  margin: 20px 0;
}

#admin-gallery .gallery-inner-blk .gallery-content-blk {
  margin-top: 25px;
  display: inline-block;
  text-align: center;
}

#admin-gallery .gallery-inner-blk .gallery-content-blk .mask-blk {
  width: 330px;
  height: 203px;
  margin: 5px;
  display: inline-block;
}

#admin-gallery .mask-blk .mask-top {
  width: 100%;
  height: 80%;
  background: #eee;
  position: relative;
}

#admin-gallery .mask-blk .mask-top .img-mask {
  width: 100%;
  height: 100%;
  background: lightgrey;
  opacity: 0.6;
}

#admin-gallery .position-align {
  position: absolute;
  width: 330px;
}

#admin-gallery .mask-blk .mask-top .select-and-share-blk .round input[type="checkbox"]:checked + label {
  background-color: #F16969;
  border-color: #F16969;
}

#admin-gallery .mask-blk .mask-top .select-and-share-blk {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

#admin-gallery .mask-blk .mask-top .select-and-share-blk .share-btn {
  background: #FFF;
  padding: 5px 10px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  color: #606060;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid #C5C5C5;
}

#admin-gallery .mask-blk .mask-top .select-and-share-blk .share-btn:hover {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
}

#admin-gallery .mask-blk .mask-top img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* #admin-gallery .mask-blk .mask-top .my-loader{
  background-image: url(/assets/images/defaultImg.jpg);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
} */

#admin-gallery .mask-blk .mask-bottom {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 20%;
}

#admin-gallery .mask-blk .mask-bottom .mask-info {
  font-family: ProximaNova-Regular;
  font-size: 12px;
  color: #373737;
}

#admin-gallery .mask-blk .mask-bottom .download-img {
  cursor: pointer;
  width: 80%;
}

#admin-gallery .gallery-inner-blk .showdownload-outer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 65px;
  background: rgba(255,27,27) !important;
  display: flex;
  justify-content: center;
  align-items: center
}

#admin-gallery .gallery-inner-blk .showdownload-outer.show {
  display: flex;
}

#admin-gallery .gallery-inner-blk .showdownload-outer.hide {
  display: none
}

#admin-gallery .gallery-inner-blk .showdownload-inner {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center
}

#admin-gallery .gallery-inner-blk .showdownload-outer .selected-count-title,
#admin-gallery .gallery-inner-blk .showdownload-outer .selected-title {
  color: #FFFFFF;
  font-family: ProximaNova-Bold;
  text-shadow: 0 1px 0 0 rgba(0,0,0,0.15);
}

#admin-gallery .gallery-inner-blk .showdownload-outer .selected-count-title {
  font-size: 22px;
}

#admin-gallery .gallery-inner-blk .showdownload-outer .selected-title {
  font-size: 14px;
}

#admin-gallery .gallery-inner-blk .showdownload-outer .hr-line {
  width: 1px;
  height: 100%;
  background: #A9A9A9;
}

#admin-gallery .gallery-inner-blk .showdownload-inner .download-btn {
  background: #FFF;
  padding: 10px 15px;
  border: none;
}
 
#admin-gallery .gallery-inner-blk .pagination-outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  height: 30px;
}

#admin-gallery .gallery-inner-blk .pagination-outer .pagination-inner {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  font-family: ProximaNova-Regular; 
  font-size: 14px;
  text-transform: capitalize;
}

#admin-gallery .gallery-inner-blk .pagination-outer .pagination-inner .previous-blk,
#admin-gallery .gallery-inner-blk .pagination-outer .pagination-inner .next-blk {
  cursor: pointer;
}

#admin-gallery .gallery-inner-blk .pagination-outer .pagination-inner .number-blk span {
  padding: 0 10px;
  cursor: pointer;
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row {
    display:block !important;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk {
    text-align:center !important;
    margin-bottom: 20px;
  }
  
  #admin-gallery .gallery-inner-blk .gallery-top-blk .filter-outer-blk {
    display: block !important;
    width: 100% !important;
  }

  #admin-gallery .gallery-inner-blk {
    padding-top: 20px !important;
  }

  #admin-gallery .filter-inner-blk{
    display: block !important;
  }

  #admin-gallery .filter-inner-blk .search-blk, #admin-gallery .filter-inner-blk .filter-blk {
    width: 100% !important;
    margin-bottom: 10px;
  }

  #admin-gallery .filter-inner-blk .search-blk {
    position: relative;
  }

  #admin-gallery .filter-inner-blk .search-blk img {
    position: absolute;
    left:6%;
  }

  #admin-gallery .filter-inner-blk .search-blk input {
    padding-left:30px;
  }

  .clr {
    clear: both;
  }

  #admin-gallery .gallery-inner-blk .gallery-content-outer {
    padding: 20px 0;
  }
  
  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row {
    margin:40px 0px 0px 5px;
    padding: 0px 10px;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .result-found {
    text-align: center;
    padding:10px 0px;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk {
    display: block;
    padding: 10px 0px;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option {
    display: inline-block;
    margin:5px;
  }

  #maskpreview .pre-mask-blk {
    width:100%;
  }

  #maskpreview .pre-mask-blk .mask-top {
    display: inline-block;
    height: auto;
  }
  
  #maskpreview .pre-mask-blk .mask-top .share-outer {
    left:0;
  }

}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

/* @media (min-width: 481px) and (max-width: 767px) {
  
} */

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row {
    display:block !important;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-top-row .title-blk {
    text-align:center !important;
    margin-bottom: 20px;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .filter-outer-blk {
    display: block !important;
    width: 100% !important;
  }

  #admin-gallery .gallery-inner-blk {
    padding-top: 20px !important;
  }

  #admin-gallery .filter-inner-blk {
    display: block !important;
  }

  #admin-gallery .filter-inner-blk .search-blk, #admin-gallery .filter-inner-blk .filter-blk {
    width: 100% !important;
    margin-bottom: 10px;
  }

  #admin-gallery .filter-inner-blk .search-blk {
    position: relative;
  }

  #admin-gallery .filter-inner-blk .search-blk img {
    position: absolute;
    left:6%;
  }

  #admin-gallery .filter-inner-blk .search-blk input {
    padding-left:30px;
  }

  .clr {
    clear: both;
  }

  #admin-gallery .gallery-inner-blk .gallery-content-outer {
    padding: 20px 0;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row {
    margin:40px 0px 0px 5px;
    padding: 0px 10px;
    flex-direction: column;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .result-found {
    text-align: center;
    padding:10px 0px;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk {
    display: block;
    padding: 10px 0px;
  }

  #admin-gallery .gallery-inner-blk .gallery-top-blk .gallery-bottom-row .selected-filter-blk .selected-filter-option {
    display: inline-block;
    margin:5px;
  }

  #maskpreview .pre-mask-blk {
    width:100%;
  }

  #maskpreview .pre-mask-blk .mask-top {
    display: inline-block;
    height: auto;
  }

  #maskpreview .pre-mask-blk .mask-top .share-outer {
    left:0;
    top: -42px;
  }

  #maskpreview .pre-mask-blk .mask-top .share-blk {
    padding: 0 15px 0 0;
  }

  #maskpreview .pre-mask-blk .mask-top .share-blk .share-btn {
    display: flex;
  }

  #admin-gallery .gallery-inner-blk .showdownload-inner {
    padding: 0 15px;
    width: unset;
  }

  #overlay .overlay-block.img {
    width: 100%;
    height: 38%;
  }

  #maskpreview .pre-mask-blk {
    height: unset;
  }

  #maskpreview .navigation-outer .navigation-blk {
    top: 35%;
  }

  #admin-gallery .gallery-inner-blk .showdownload-outer {
    background: rgba(255,27,27)!important;
  }  
}