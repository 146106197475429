#teacher-on-board-success {
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
}

#teacher-on-board-success #outer-blk {
  /* display: flex;
  background: #FFF;
  height: 100vh;
  width: 100%; */

  background-image: url('/public/assets/images/signupSuccessBg.png');
  /* Full height */
  /* height: 1400px;  */
  min-height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: center;
}

#teacher-on-board-success #outer-blk .logo-blk {
  position: absolute;
  top: 30px;
  left: 40px;
  display: flex;
  align-items: center;
}

#teacher-on-board-success #outer-blk .logo-blk .logo-img {
  width: 130px;
}

#teacher-on-board-success #outer-blk #right-side-blk {
  background-color: white;
} 

#teacher-on-board-success #outer-blk #left-side-blk {
  width: 40%;
  background: White;
} 

/*media query*/
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  #teacher-on-board-success #outer-blk #right-side-blk img {
    height: 70vh;
  }
   
  #teacher-on-board-success {
    overflow: auto;
  }

  #teacher-on-board-success .logo-blk {
    position: absolute;
  }

  #teacher-on-board-success #outer-blk {
    display: block;
  }

  #teacher-on-board-success #outer-blk #right-side-blk,#teacher-on-board-success #outer-blk #left-side-blk {
    width:100%;
  }

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  #teacher-on-board-success #outer-blk #right-side-blk img {
    height: 70vh;
  }
   
  #teacher-on-board-success {
    overflow: auto;
  }

  #teacher-on-board-success .logo-blk {
    position: absolute;
  }

  #teacher-on-board-success #outer-blk {
    display: block;
  }

  #teacher-on-board-success #outer-blk #right-side-blk,#teacher-on-board-success #outer-blk #left-side-blk {
    width:100%;
  }

}