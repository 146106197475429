#masksuccess {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  padding: 20px;
}


#masksuccess .masksuccess-outer {
  width: 38%;
  display: grid;
}

#masksuccess .masksuccess-outer .title,
#masksuccess .masksuccess-outer .desc {
  color: #333333;
  text-align: center;
}

#masksuccess .share-outer .title,
#masksuccess .share-outer .desc {
  color: #333333;
  text-align: center;
}

#masksuccess .masksuccess-outer .title {
  font-family: ProximaNova-Bold;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

#masksuccess .share-outer .title {
  font-family: ProximaNova-Bold;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  
}

#masksuccess .masksuccess-outer .desc {
  font-family: ProximaNova-Regular;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

#masksuccess .share-outer .desc {
  font-family: ProximaNova-Regular;
  font-size: 14px;
  margin-bottom: 50px;
  text-align: center;
}

#masksuccess .masksuccess-outer .desctext {
  font-family: ProximaNova-Regular;
  font-size: 14px;
  margin-bottom: 15px;
  color: #333333;
  text-align: center;
}

#masksuccess .share-outer .desctext {
  font-family: ProximaNova-Regular;
  font-size: 14px;
  color: #333333;
}

.icon-link{
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px;
  cursor: pointer;
}
.share-blk{
  display: grid;
}

.share-box{
  border: 1px solid black;
  margin: 5px;
  padding: 30px;
  border-radius: 5px;
}

#masksuccess .masksuccess-outer .btn-blk {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0;
}

#masksuccess .masksuccess-outer .btn-blk .go-to-gallery-btn,
#masksuccess .masksuccess-outer .btn-blk .draw-again-btn {
  border-radius: 4px;
  font-family: ProximaNova-Bold;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

#masksuccess .masksuccess-outer .btn-blk .go-to-gallery-btn {
  border: 1px solid #969696;
  background-color: #FFFFFF;
  color: #000;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

#masksuccess .masksuccess-outer .btn-blk .draw-again-btn {
  border: 1px solid #64A543;
  background-color: #64A543;
  color: #FFF;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.copy-link{
  border: 1px solid #64A543;
  background-color: #64A543;
  color: #FFF;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.instaimg{
  height: 32px;
  width: 31px;
  cursor: pointer;
}
.seperation{
  height: 450px;
  width: 100px;
  display: flex;
}
#masksuccess  .share-outer {
  display: grid;
  justify-content: center;
  align-items: center;
}

#masksuccess  .share-outer .copy-blk {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 5px 0px 15px 0px;
  
}

#masksuccess .share-outer .copy-blk .text-link{
  border-radius: 4px;
  font-size: 14px;
  padding: 2px 65px 2px 5px;
  color: #333333;
}

#masksuccess .share-outer .copy-blk .copy-link {
  border-radius: 4px;
  font-family: ProximaNova-Bold;
  padding: 5px 15px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
}

#masksuccess  .share-outer .share-blk .button {
  outline: none; 
}

#masksuccess .share-outer .share-label {
  font-family: ProximaNova-Bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #606060;
  text-align: center;
}