#help-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#help-form .help-outer-blk {
  width: 75%;
}

#help-form .help-blk {
  margin: 50px 30px;
}

#help-form .help-blk .help-title {
  font-size: 26px;
  font-family: ProximaNova-Bold;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 30px;
}

#help-form .help-blk .vertical-field-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

#help-form .help-blk .vertical-field-group .label-input {
  width: 100%;
  position: relative;
}

#help-form .help-blk label {
  font-size: 12px;
  text-transform: capitalize;
  margin: 10px 0;
}

#help-form .help-blk .vertical-field-group textarea,
#help-form .help-blk .vertical-field-group input[type=text],
#help-form .help-blk .vertical-field-group input[type=password],
#help-form .help-blk .vertical-field-group input[type=number],
#help-form .help-blk .vertical-field-group select {
  width: 93%;
}

#help-form .help-blk textarea,
#help-form .help-blk input[type=text],
#help-form .help-blk input[type=password],
#help-form .help-blk input[type=number],
#help-form .help-blk select {
  border: 1px solid #ddd;
  padding: 15px 10px;
  border-radius: 5px;
  font-size: 12px;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-sizing:content-box; 
  margin: 10px 0;
}

 #help-form .help-blk input:focus,
 #help-form .help-blk select:focus {
  outline: none;
}

#help-form .help-blk  .vertical-field-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/public/assets/images/icons/ic_dropdown.svg) 98% / 1.6% no-repeat transparent
}


#help-form .bottom-section {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}  

#help-form .bottom-section .submit-blk {
  display: inline-block;
  width: unset;
  height: unset;
  padding:10px 25px;
  text-align: center;
  margin: 0;
  cursor: pointer;
}

#help-form .bottom-section .submit-blk .submit-btn{ 
  background-color: #64A543;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase;
}

#help-form .bottom-section .cancel-blk {
  display: inline-block;
  width: unset;
  height: unset;
  padding:10px 25px;
  text-align: center;
  margin: 0;
  cursor: pointer;
}

#help-form .bottom-section .cancel-blk .cancel-btn {
  color: #7F7F7F;
  text-transform: uppercase;
  font-size: 12px;
}
