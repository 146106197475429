#download .download-outer-blk {
  width: 100%;
  height: 100%;
  background: #373737;
}

#download .download-inner-blk {
  padding: 25px 0;
  width: 90%;
  max-width: 1024px;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
}

#download .download-inner-blk .warning-text{
  font-family: ProximaNova-Regular;
  margin-left: 38%;
  color: #64A543;
  font-size: 14px;
}

#download .download-inner-blk .download-top-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#download .download-inner-blk .download-top-blk .btn-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 270px;
}

#download .download-inner-blk .download-bottom-blk{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#download .download-inner-blk .div-line {
  height: 1px;
  width: 100%;
  background-color: #DDD;
  margin: 10px 0;
}

#download .download-inner-blk .download-top-blk .download-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
  height: 70px;
}

#download .download-inner-blk .download-bottom-blk .download-bottom-row {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

#download .download-inner-blk .title-blk {
  font-family: ProximaNova-Bold;
  color: #FFFFFF;
  font-size: 17px;
  line-height: 29px;
}

#download .download-top-row .drawmask-btn-section {
  display: flex;
  align-items: center;
  background-color: white;
  color: #64A543;
  padding: 10px 15px;
  border-radius: 5px;
}

#download .filter-outer-blk {
  font-family: ProximaNova-Regular;
  font-size: 12px;
  width: 50%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  border-radius: 5px;
}

#download .filter-inner-blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
}

#download .filter-inner-blk .search-blk {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
}

#download .filter-inner-blk .search-blk input {
  height: 40px;
  width: 85%;
  border: none;
  font-size: 11px;
  font-family: ProximaNova-Regular;
  border-radius: 5px;
}

#download .draw-btn {
  width: 120px;
  position: unset;
  background: #E3E3E3;
  border: 1px solid #60A550;
  border-radius: 6px;
}
#download .draw-btn p {
  font-family: ProximaNova-Bold;
  text-transform: uppercase;
  font-size: 14px;
  color: #60A550;
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

#download .draw-btn.disable {
  pointer-events: none;
  cursor: not-allowed;
  background: lightcoral;
}

@media (min-width: 320px) and (max-width: 480px) {
  
  #download .draw-btn {
    width: unset;
    padding: 0 5px;
  }
  #download .download-bottom-row .draw-btn {
    width: unset;
    margin: 0 25%;
  }

  #download .draw-btn .arrow-white-right {
    display: none;
  }
  
  #download .draw-btn p {
    font-size: 10px;
    padding: 0 5px;
  }

  #download .draw-btn.disable {
    pointer-events: none;
    cursor: not-allowed;
    background: lightcoral;
  }

  #download .download-inner-blk .title-blk {
    text-align:center !important;
    margin-bottom: 10px;
    font-family: ProximaNova-Regular;
    font-size: 12px;
  }

  #download .download-inner-blk .download-top-blk .download-top-row {
    display:block !important;
    padding-bottom: 40px;
    width: unset;
  }

  #download .download-inner-blk .download-top-blk .download-outer-blk {
    display: block !important;
    width: 100% !important;
  }

  #download .download-inner-blk .download-bottom-blk .download-bottom-row {
    display:block !important;
    align-items: center;
    padding-top: 40px;
    width: 75%;
  }

  #download .download-inner-blk {
    padding-top: 20px !important;
  }

  #download .filter-inner-blk {
    display: block !important;
  }

  #download .filter-inner-blk .search-blk, #download .filter-inner-blk .filter-blk {
    width: 100% !important;
    margin-bottom: 10px;
  }

  #download .filter-inner-blk .search-blk {
    position: relative;
  }

  #download .filter-inner-blk .search-blk img {
    position: absolute;
    left:6%;
  }

  #download .filter-inner-blk .search-blk input {
    padding-left:30px;
  }

  #download .filter-outer-blk {
    width: 100%;
    margin-bottom: 20px;
  }

}